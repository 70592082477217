<template>
<div>
    <el-drawer v-model="drawerVisible" title="用户中心"
                :direction="elDrawerAttr.direction" :size="elDrawerAttr.size" >
        <el-row>
            <el-col :span="4">
                <router-link :to="'/user/person'">
                    <el-button>用户信息</el-button>
                </router-link>
            </el-col>
            <el-col :span="4" :offset="1" v-if="user.privilege >= 5">
                <router-link :to="'/user/paper'">
                    <el-button>我的试题</el-button>
                </router-link>
            </el-col>
            <el-col :span="4" :offset="1">
                <router-link :to="'/user/shop/work'">
                    <el-button>拥有商品</el-button>
                </router-link>
            </el-col>
        </el-row>
    </el-drawer>
</div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue';
import store from '@/store/index.js';

const drawerVisible = ref(false); // 抽屉是否可见

const windowWidth = ref(0); // 屏幕宽度

const elDrawerAttr = reactive({ // 抽屉参数
    direction: 'rtl',
    size: '30%'
})

const user = reactive({
    email: null,
    privilege: null,
})

onMounted(() => {
    Object.assign(user, store.getters['UserStore/getUser']);
    getWindowResize();
    window.addEventListener('resize', getWindowResize);
})

// 获取屏幕尺寸
const getWindowResize = () => {
    windowWidth.value = window.innerWidth;
    if (windowWidth.value > 800) {
        Object.assign(elDrawerAttr, {
            direction: 'rtl',
            size: '30%'
        })
    } else {
        Object.assign(elDrawerAttr, {
            direction: 'btt',
            size: '80%'
        })
    }
}

/**
 * 打开抽屉
 */
const open = () => {
    drawerVisible.value = true;
}

const close = () => {
    drawerVisible.value = false;
}

defineExpose({
    open,
    close
})

</script>

<style scoped>

</style>