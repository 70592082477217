<template>
    <el-dialog v-model="dialogVisible" title="登录" :width="isMobile() ? 800 : 400">
        <div class="my-login-el-dialog-img-box">
            <!-- <img src="../../../../static/relative.jpg" style="margin-bottom: 20px;"/> -->
            <img :src="'https://varitall.oss-cn-beijing.aliyuncs.com/icon/varitall-3.png'" 
                        style="height: 45px; margin-bottom: 20px;"/>
        </div>

        <el-form :model="form" label-width="70" style="padding: 10px 30px 10px 30px;">
            <el-form-item label="邮箱">
                <el-input v-model="form.email" style="height: auto;" placeholder="请输入邮箱" autocomplete="off" />
            </el-form-item>
            <el-form-item label="密码">
                <el-input v-model="form.password" type="password" @keyup.enter="handleLoginEnter()" placeholder="请输入密码" autocomplete="off" />
            </el-form-item>
        </el-form>

        <template #footer>
            <!-- <div class="dialog-footer">
                <p @click="mySwitch">已有账号？去登录</p>
                <el-button @click="close">取消</el-button>
                <el-button type="primary" @click="login">
                    确定
                </el-button>
            </div> -->
            <div class="button-box" v-loading.fullscreen.lock="isLoading">
                <button @click="close" style="font-size: 16px;">取消</button>
                <button @click="login" style="margin-left: 10px; font-size: 16px;">确定</button>
                <p @click="toRegister" style="margin-left: 10px;">没有账号？去注册</p>
            </div>
        </template>
    </el-dialog>
    
</template>

<script setup>
import { reactive, ref, getCurrentInstance } from "vue";
import store from '@/store/index.js'
import UserService from '@/service/user/index.js';
import { isMobile } from "@/util/is-mobile";

let isLoading = ref(false);
let dialogVisible = ref(false);

const form = reactive({
    email: null,
    password: null
})

const { proxy } = getCurrentInstance()

const login = async () => {
    isLoading.value = true;
    const res = await UserService.postUserLoginAPI(form);
    if (res.code == 200) {
        store.commit('UserStore/updateUser', res.data);
        proxy.$message.success({ // success、info、warning
            message: '登录成功',
        })
        location.reload();
    } else {
        proxy.$message.error({ // success、info、warning
            message: res.message,
        })
    }
    isLoading.value = false
}

/**
 * 处理回车登录
 */
const handleLoginEnter = async () => {
    login();
}

const emit = defineEmits(['toRegister']) 
const toRegister = () => {
    close();
    emit('toRegister');
    // 该组件被引用的地方，会给 @toRegister 绑定一个函数，emit('toRegister') 就是调用那个函数
}

const open = () => {
    dialogVisible.value = true;
};
 

const close = () => {
    dialogVisible.value = false;
}

defineExpose({
	open,
    close
});
</script>

<style scoped>
.my-login-el-dialog-img-box {
    width: 100%; 
    display: flex; 
    align-items: center; 
    justify-content: center;
}
.my-login-el-dialog-img-box img {
    width: 150px; 
    height: 150px;
}

@media screen and (max-width: 1080px) {
::v-deep .el-form .el-form-item__label {
   
}

::v-deep .el-input__inner {
    
}

}


</style>