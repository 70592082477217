<template>
    <div class="my-music-player-box">
        <div>
            <el-button @click="handleOpenDrawer" :disabled="openDrawerDisabled" style="width: 40px; height: 40px; border-radius: 50%;">
                <img style="width: 40px; height: 40px;" src="../../../static/music/music.png">
            </el-button>
        </div>
    </div>
    <el-drawer v-model="drawerVisible" title="音乐列表"
                :direction="elDrawerAttr.direction" :size="elDrawerAttr.size" >
        <div class="my-music-player-drawer-box">
            <div style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                <div style="margin-bottom: 10px;">
                    <p style="font-family: kaiti; font-size: 22px;">
                        {{ musicDrawer.musicList[musicDrawer.selectedIndex].filename.substring(0, musicDrawer.musicList[musicDrawer.selectedIndex].filename.lastIndexOf('.')) }}
                    </p>
                </div>
                <!-- audio -->
                <audio style="width: 400px;" id="myAudio" controls controlsList="nodownload" oncontextmenu="return false" 
                    :src="musicDrawer.baseUrl + musicDrawer.musicList[musicDrawer.selectedIndex].filename"
                    @ended="handlePlayNext()">
                </audio>
                <!-- 播放按钮 -->
                <div style="margin-top: 10px; width: 100%; display: flex; align-items: center; justify-content: center;">
                    <div style="">
                        <el-button @click="handlePlayNext" style="width: 30px; height: 30px; border: none;">
                            <img src="../../../static/music/next_play.png" style="width: 30px; height: 30px;"/>
                        </el-button>
                    </div>
                    <div style="margin-left: 20px;">
                        <el-button @click="handleRandomPlay" style="width: 30px; height: 30px; border: none;">
                            <img src="../../../static/music/random_play.png" style="width: 25px; height: 25px;"/>
                        </el-button>
                    </div>
                    <div style="margin-left: 20px;">
                        <el-button @click="handleListPlay" style="width: 30px; height: 30px; border: none;">
                            <img src="../../../static/music/list_play.png" style="width: 30px; height: 30px;"/>
                        </el-button>
                    </div>
                    <div style="margin-left: 20px;">
                        <el-tag style="font-size: 18px; padding: 15px 10px 15px 10px;" type="primary">{{ playModule }}</el-tag>
                    </div>
                </div>
            </div>

            <div style="margin-top: 10px; width: 450px;">
                <el-table :data="musicDrawer.musicList" style="width: auto;">
                    <el-table-column label="歌曲">
                        <template #default="scope">
                            {{ scope.row.filename.substring(0, scope.row.filename.lastIndexOf('.')) }}
                        </template>
                    </el-table-column>
                    <el-table-column :align="'right'">
                        <template #default="scope">
                            <el-button @click="handleStartPlayMusic(scope.$index, scope.row)" v-show="!scope.row.playing" style="width: 30px; height: 30px; border-radius: 50%;">
                                <img style="width: 30px; height: 30px;" src="../../../static/music/start_play.png"/>
                            </el-button>
                            <el-button @click="handlePausePlayMusic(scope.$index, scope.row)" v-show="scope.row.playing" style="width: 30px; height: 30px; border-radius: 50%;">
                                <img class="rotate-img" style="width: 30px; height: 30px;" src="../../../static/music/pause_play.png"/>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </el-drawer>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue';
import store from '@/store/index.js';

const playModule = ref('顺序播放');

const drawerVisible = ref(false); // 抽屉是否可见

const windowWidth = ref(0); // 屏幕宽度

const openDrawerDisabled = ref(true);

const elDrawerAttr = reactive({ // 抽屉参数
    direction: 'rtl',
    size: '30%'
})

const user = reactive({
    email: null,
})

const musicDrawer = reactive({ // 音乐参数
    baseUrl: 'http://139.224.42.179:8888/music/',
    selectedIndex: Math.floor(Math.random() * 15),
    musicList: [
        { filename: '周杰伦 _ Lara梁心颐 - 珊瑚海.ogg', playing: false },
        { filename: '周杰伦 - 一路向北.ogg', playing: false },
        { filename: '周杰伦 - 七里香.ogg', playing: false },
        { filename: '周杰伦 - 不能说的秘密.ogg', playing: false },
        { filename: '周杰伦 - 以父之名.ogg', playing: false },
        { filename: '周杰伦 - 反方向的钟.ogg', playing: false },
        { filename: '周杰伦 - 发如雪.ogg', playing: false },
        { filename: '周杰伦 - 夜曲.ogg', playing: false },
        { filename: '周杰伦 - 安静.ogg', playing: false },
        { filename: '周杰伦 - 我是如此相信.ogg', playing: false },
        { filename: '周杰伦 - 我落泪情绪零碎.ogg', playing: false },
        { filename: '周杰伦 - 搁浅.ogg', playing: false },
        { filename: '周杰伦 - 晴天.ogg', playing: false },
        { filename: '周杰伦 - 给我一首歌的时间.ogg', playing: false },
        { filename: '周杰伦 - 花海.ogg', playing: false },
        { filename: '周杰伦 - 蒲公英的约定.ogg', playing: false },
        { filename: '周杰伦 - 说好不哭 (with 五月天阿信).ogg', playing: false },
        { filename: '周杰伦 - 说好的幸福呢.ogg', playing: false },
    ]
})

onMounted(() => {
    Object.assign(user, store.getters['UserStore/getUser']);
    if (user.email && user.privilege == 10) {
        // 管理员才可以播放
        openDrawerDisabled.value = false;
        Object.assign(musicDrawer.musicList[musicDrawer.selectedIndex], {
            playing: true
        })
    }
    getWindowResize();
    window.addEventListener('resize', getWindowResize);
})

/**
 * 处理播放音乐
 * @param {*} index 
 * @param {*} row 
 */
const handleStartPlayMusic = (index, row) => {
    // 修改前后的两首音乐的 playing 状态
    Object.assign(musicDrawer.musicList[musicDrawer.selectedIndex], {
        playing: false
    })    
    Object.assign(musicDrawer, {
        selectedIndex: index
    })
    Object.assign(musicDrawer.musicList[index], {
        playing: true
    })


    const audio = document.getElementById("myAudio");
    setTimeout(() => { // 等我一会
        audio.play();
    }, 500); 
    
}


/**
 * 处理暂停音乐
 * @param {*} index 
 * @param {*} row 
 */
const handlePausePlayMusic = (index, row) => {
    // 将当前 playing 的音乐设置为 false
    Object.assign(musicDrawer.musicList[musicDrawer.selectedIndex], {
        playing: false
    })

    const audio = document.getElementById("myAudio");
    setTimeout(() => {
        audio.pause();
    }, 500);
}

/**
 * 处理播放下一首音乐
 */
const handlePlayNext = () => {
    let nextIndex;
    if (playModule.value == '随机播放') {
        nextIndex = Math.floor(Math.random() * musicDrawer.musicList.length);
    } 
    if (playModule.value == '顺序播放') {
        nextIndex = musicDrawer.selectedIndex + 1;
        if (nextIndex == musicDrawer.musicList.length) {
            nextIndex = 0;
        }
    }
    handleStartPlayMusic(nextIndex, null); // 传入 nextIndex，函数内会修改 selectedIndex
}

/**
 * 处理随机播放
 */
const handleRandomPlay = () => {
    playModule.value = '随机播放';
}

/**
 * 处理列表播放
 */
const handleListPlay = () => {
    playModule.value = '顺序播放';
}

// 获取屏幕尺寸
const getWindowResize = () => {
    windowWidth.value = window.innerWidth;
    if (windowWidth.value > 800) {
        Object.assign(elDrawerAttr, {
            direction: 'rtl',
            size: '30%'
        })
    } else {
        Object.assign(elDrawerAttr, {
            direction: 'btt',
            size: '80%'
        })
    }
}

/**
 * 打开抽屉
 */
const handleOpenDrawer = () => {
    drawerVisible.value = true;
}

</script>

<style scoped>
.my-music-player-box{
    position: fixed;
    bottom: 10vh;
    right: 2vw;
}

.my-music-player-drawer-box {
    height: auto;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

}


</style>