import request from "@/util/request.js"

/**
 * 发送修改密码的验证码
 * @param {*} email 
 * @returns 
 */
export default async (email) => {
	return request({
		method: 'GET',
		url: '/api/sms/email/sendModifyPassword/' + email
	})
}