<template>
<div>
    <el-cascader 
        @change="handleCascaderChange"
        v-model="form.tagIdList"
        :placeholder="'可输入查找或选择'"
        :options="cascader.options" 
        :props="cascader.props" 
        :show-all-levels="true"
        :max-collapse-tags="1"
        collapse-tags 
        clearable 
        filterable
        style="width: 300px;"/>
    <el-button style="margin-left: 10px;" @click="handleEditTag">编辑标签</el-button>
</div>
</template>

<script setup>
import { reactive, ref, getCurrentInstance, watch, onMounted } from "vue";
import ProblemService from '@/service/problem/index.js';
import store from '@/store/index.js';

const { proxy } = getCurrentInstance();

const form = reactive({
    tagIdList: []
})

const props = defineProps({
    form: Object,
    cascader: Object
})

const cascader = reactive({
    props: {
        multiple: true,
        checkStrictly: true,
        value: 'id',
        label: 'title',
        children: 'children'
    },
    options: [
        {
            id: 0,
            title: '「 目录 」'
        }
    ]
});

const emit = defineEmits(['handleChange'])


watch(
    () => props.form,
    (value, oldValue) => {
        Object.assign(form, value);
    },
    { immediate: true, deep: true } // deep 设为 true 可以监听到 Object 内的属性变化
)

watch(
    () => props.cascader,
    (value, oldValue) => {
        if (value != null) {
            Object.assign(cascader.props, value.props)
        }
    },
    { immediate: true, deep: true } // deep 设为 true 可以监听到 Object 内的属性变化
)


onMounted(async () => {
    await getTagIdList();
})


/**
 * 处理级联选择器变化
 * @param value 
 */
 const handleCascaderChange = async(value) => {
    console.log(value);
    if (cascader.props.multiple == false) {
        emit('handleChange', value[value.length - 1])
    } else {
        const tagIdList = [];
        for (let item of value) {
            tagIdList.push(item[item.length - 1]); 
            // 每选取一个点，cascader就会多一个数组，代表当前选取节点的路经，因此取每一个数组的最后一个值
        }
        emit('handleChange', tagIdList);
    }
    
}

/**
 * 获取 tag 列表
 */
 const getTagIdList = async() => {
    const res = await ProblemService.getProblemTagIdListAPI();
    if (res.code == 200) {
        cascader.options.length = 1;
        for (let item of res.data) cascader.options.push(item);
    } else {
        proxy.$message.error({
            message: res.message
        })
    }
}

/**
 * 跳转至tag编辑页面
 */
const handleEditTag = async() => {
    proxy.$router.push('/problem/tag/edit')
}

</script>

<style>

</style>