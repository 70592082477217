<template>
<div>
    <el-table 
        :data="table"
        :border="true"
        style="height: 550px;">

        <el-table-column fixed="left" width="200">
            <template #header>
                <span style="font-size: 18px; color: #606060;">标题</span>
            </template>
            <template #default="scope">
                <span style="color: #000;">{{ scope.row.title }}</span>
                <span style="color: red;">{{ scope.row.enabled ? '' : '[未启用]' }}</span>
            </template>
        </el-table-column>

        <el-table-column fixed="left" width="200">
            <template #header>
                <span style="font-size: 18px; color: #606060;">文件</span>
            </template>
            <template #default="scope">
                <span v-if="scope.row.filename == null || scope.row.filename == ''" style="color: red;">未上传</span>
                <span v-else style="color:darkcyan;">已上传</span>
            </template>
        </el-table-column>

        <el-table-column>
            <template #header>
                <span style="font-size: 18px; color: #606060;">已选标签</span>
            </template>
            <template #default="scope">
                <div v-if="tagIdMap.size > 0">
                    <el-tag type="primary" v-for="item in scope.row.tagIdList" :key="item" style="margin-top: 5px; margin-right: 10px;">
                        {{ tagIdMap.get(String(item)) != null ? tagIdMap.get(String(item)).title : '' }}
                    </el-tag>
                </div>
            </template>
        </el-table-column>

        <el-table-column prop="createTime" width="200">
            <template #header>
                <span style="font-size: 18px; color: #606060;">创建时间</span>
            </template>
        </el-table-column>

        <el-table-column fixed="right" width="240">
            <template #header>
                <span style="font-size: 18px; color: #606060;">操作</span>
            </template>
            <template #default="scope">
                <el-button link type="warning" size="default" @click="handleDetail(scope.$index, scope.row)">
                    查看
                </el-button>
                <el-button link type="success" size="default" @click="handleUpload(scope.$index, scope.row)">
                    上传文件
                </el-button>
                <el-button link type="primary" size="default" @click="handleEdit(scope.$index, scope.row)">
                    编辑
                </el-button>
                <el-popconfirm 
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    cancel-button-type="danger"
                    width="auto"
                    :title="`确定要删除 [${scope.row.title}] 吗？`"
                    @confirm="handleDelete(scope.$index, scope.row)">
                    <template #reference>
                        <el-button link type="danger" size="default">
                            删除
                        </el-button>
                    </template>
                </el-popconfirm>
            </template>
        </el-table-column>
    </el-table>
    <my-problem-paper-upload-dialog ref="myproblempaperuploaddialog" :form="form"></my-problem-paper-upload-dialog>
</div>
</template>

<script setup>
import { ref, reactive, onMounted, getCurrentInstance, watch } from "vue";
import store from "@/store/index.js";
import ProblemService from '@/service/problem/index.js';

const { proxy } = getCurrentInstance()

const myproblempaperuploaddialog = ref();

const tagIdMap = new Map();

const table = reactive([]);

const props = defineProps({
    table: Array
})

watch(
    () => props.table,
    (value, oldValue) => {
        table.length = 0;
        for (let item of value) table.push(item);
    },
    { immediate: true, deep: true }
)

onMounted(async() => {
    await getTagIdMap();
})


/**
 * 查看paper具体页面
 * @param index 
 * @param row 
 */
const handleDetail = async(index, row) => {
    let { href } = proxy.$router.resolve({ path: `/problem/paper/detail/${row.id}` })
    window.open(href, '_blank')
}

const form = reactive({
    id: null,
    title: null
})

/**
 * 上传文件
 * @param index 
 * @param row 
 */
const handleUpload = async(index, row) => {
    Object.assign(form, row);
    myproblempaperuploaddialog.value.open();
}

const emit = defineEmits(['handleEdit'])
/**
 * 编辑
 * @param index 
 * @param row 
 */
const handleEdit = async(index, row) => {
    emit('handleEdit', row);
}

/**
 * 删除
 * @param index 
 * @param row 
 */
const handleDelete = async(index, row) => {
    const res = await ProblemService.deleteProblemPaperByIdAPI(row.id);
    if (res.code == 200) {
        proxy.$message.success({
            message: '删除成功'
        })
    } else {
        proxy.$message.error({
            message: '删除失败'
        })
    }
}

/**
 * 获取 tag Map
 */
 const getTagIdMap = async() => {
    const res = await ProblemService.getProblemTagIdMapAPI();
    if (res.code == 200) {
        // 返回值是一个对象，要用 in 获取 key（of 是用来遍历数组的）
        for (let key in res.data) {
            const value = res.data[key];
            tagIdMap.set(key, value);
        }
    }
}


</script>

<style>

</style>