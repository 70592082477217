<template>
    <el-dialog v-model="dialogVisible" title="创建/编辑评论" width="400">
        <el-form :model="form" label-width="70" style="padding: 10px 30px 10px 30px;">
            <el-form-item label="文章ID">
                <el-input style="width: 300px;" v-model="form.articleId" placeholder="文章ID" autocomplete="off" />
            </el-form-item>
            <el-form-item label="父评论ID">
                <el-input style="width: 300px;" v-model="form.parentId" placeholder="父评论ID" autocomplete="off" />
            </el-form-item>
            <el-form-item label="通过审核">
                <el-select v-model="form.checked" :value="form.checked ? '通过' : '未通过'">
                    <el-option :value="true">通过</el-option>
                    <el-option :value="false">未通过</el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="用户邮箱">
                <el-input style="width: 300px;" v-model="form.userEmail" placeholder="请输入邮箱" autocomplete="off" />
            </el-form-item>
            <el-form-item label="评论内容">
                <el-input style="width: 300px;" v-model="form.content" placeholder="请输入评论内容" autocomplete="off" />
            </el-form-item>
            <el-form-item label="评论时间">
                <el-input style="width: 300px;" v-model="form.createTime" placeholder="请输入评论时间" autocomplete="off" />
            </el-form-item>
        </el-form>

        <template #footer>
            <div>
                <el-button @click="close">取消</el-button>
                <el-button @click="confirm" :loading="confirmButtonLoding" type="primary">确定</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script setup>
import { reactive, ref, getCurrentInstance, watch } from "vue";
// import UserService from "@/service/user/index.js";

const { proxy } = getCurrentInstance()

const confirmButtonLoding = ref(false);

let dialogVisible = ref(false);

const props = defineProps({
    form: {
        type: Object
    }
})

const form = reactive({
    
})

watch(
    () => props.form,
    (value, oldValue) => {
        Object.assign(form, value);
    },
    { immediate: true, deep: true } // deep 设为 true 可以监听到 Object 内的属性变化
)

const confirm = async () => {
    confirmButtonLoding.value = true;
    const res = 0;
    confirmButtonLoding.value = false;
    if (res.code == 200) {
        location.reload();
        proxy.$message.success({
            message: '保存成功',
        })
    } else {
        proxy.$message.info({
            message: res.message,
        })
    }
    
}


const open = () => {
    dialogVisible.value = true;
};
 

const close = () => {
    dialogVisible.value = false;
}

defineExpose({
	open,
    close
});
</script>

<style>

</style>