<template>
<div>
    <el-dropdown 
        v-if="menuList.length > 0"
        :show-timeout="0" 
        :hide-timeout="500"
        :trigger="'click'" 
        :placement="'bottom-start'">
        <el-button type="primary" style="border-radius: 0; width: 200px; height: 40px; margin-top: 20px;">
            <p style="font-size: 18px;">
                {{ index.title }} 
            </p>
            <el-icon class="el-icon--right"><arrow-down /></el-icon>
        </el-button>
        <template #dropdown>
            <el-card class="el-dropmenu-card">
                <el-row>
                    <el-col :span="24">
                        <el-menu 
                            :default-active="index.menuIndex"
                            class="el-menu-demo"
                            mode="horizontal"
                            style="height: 40px; width: 500px;"
                            @select="handleSelectMenuItem">
                            <el-menu-item v-for="item in menuList" :key="item.index" :index="item.index">
                                {{ item.title }}
                            </el-menu-item>
                        </el-menu>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-button v-for="item in menuList[parseInt(index.menuIndex) - 1].subMenuList"  
                            :key="item.index"  
                            style="margin: 10px 20px 10px 0px;"
                            @click="handleSelectSubMenuItem(item.index, item.subjectId)">
                            {{ item.title }}
                        </el-button>
                    </el-col>
                </el-row>
            </el-card>
        </template>
    </el-dropdown>
</div>
</template>

<script setup>
import { ref, reactive, onMounted, watch } from 'vue';
import { ArrowDown } from '@element-plus/icons-vue'
import store from '@/store/index.js';
import ProblemService from '@/service/problem/index.js';

const index = reactive({
    title: '初中物理',
    subjectId: 1,
    menuIndex: '1',
    subMenuIndex: '1'
})

const menuList = reactive([])


onMounted(async () => {
    const storeIndex = store.getters['ProblemStore/getIndex'];
    if (storeIndex.subjectId == null) {
        store.commit('ProblemStore/updateIndex', index);
    } else {
        Object.assign(index, storeIndex);
    }
    await getSubjectList();
})


const getSubjectList = async() => {
    const res = await ProblemService.getProblemSubjectIdListAPI();
    if (res.code == 200) {
        const map = new Map();
        for (let item of res.data) {
            if (map.has(item.grade)) map.get(item.grade).push(item);
            else map.set(item.grade, [item]);
        }

        menuList.length = 0;
        let i = '1';
        for (let item of map) {
            const key = item[0], value = item[1], children = [];
            let j = '1';
            for (let y of value) {
                children.push({
                    index: j,
                    subjectId: y.id,
                    title: y.title
                })
                j = String((parseInt(j) + 1));
            }
            menuList.push({
                index: i,
                title: key,
                subMenuList: children
            })
            i = String((parseInt(i) + 1));
        }
    } else {
        proxyRefs.$message.error({
            message: res.message
        })
    }
}


/**
 * 点击导航菜单
 * @param key 
 * @param keyPath 
 */
const handleSelectMenuItem = (key, keyPath) => {
    index.menuIndex = key;
}


const emit = defineEmits(['changeTreeNode']);
/**
 * 点击导航子菜单
 * @param value 
 */
const handleSelectSubMenuItem = (value, subjectId) => {
    index.subMenuIndex = value;
    const title = menuList[parseInt(index.menuIndex) - 1].title + 
        menuList[parseInt(index.menuIndex) - 1].subMenuList[parseInt(index.subMenuIndex) - 1].title
    Object.assign(index, {
        subjectId: subjectId,
        title: title
    })
    store.commit('ProblemStore/updateIndex', index);
    store.commit('ProblemStore/removeTag');
    emit('changeTreeNode');
}

</script>

<style scoped>
.el-dropmenu-card >>> .el-card__body {
    padding: 0px 20px 0px 20px;
}
</style>