<template>
    <el-dialog v-model="dialogVisible" title="修改信息" width="400">
        <div style="width: 100%; background-color: #fff; border-radius: 30px;">
            <div style="width: 100%; padding-bottom: 30px;" v-loading.fullscreen.lock="isLoading">
                
                <el-divider content-position="center">$</el-divider>
                
                <div style="width: 100%; margin: 0 auto;">
                    <my-upload-avatar-button></my-upload-avatar-button>
                </div>

                <el-divider content-position="center">$</el-divider>

                <div style="width: 100%; margin: 0 auto;">
                  
                    <el-form :model="modifyUsernameFormData" size="large" label-width="70" style="padding: 10px 30px 10px 30px;">
                        <el-form-item label="New" prop="username">
                            <el-input type="text" v-model="modifyUsernameFormData.username" placeholder="请输入新用户名" autocomplete="off" />
                        </el-form-item>
                    </el-form>

                </div>
                <div class="button-box">
                    <button @click="modifyUsername"
                        style="background-color: #33C0BA; color: #fff; font-size: 18px;">修改用户名</button>
                </div>

                <el-divider content-position="center">$</el-divider>

                <div style="width: 100%; margin: 0 auto;">
                    <el-form :model="modifyPasswordFormData" size="large" label-width="70" style="padding: 10px 30px 10px 30px;">
                        <el-form-item label="New">
                            <el-input type="password" show-password v-model="modifyPasswordFormData.newPassword" placeholder="请输入新密码" autocomplete="off" />
                        </el-form-item>
                        <el-form-item label="Rep">
                            <el-input type="password" show-password v-model="modifyPasswordFormData.repeatPassword" placeholder="请重复新密码" autocomplete="off" />
                        </el-form-item>
                    </el-form>
                </div>
                <div class="button-box">
                    <button @click="modifyPassword"
                        style="background-color: #33C0BA; color: #fff; font-size: 18px;">修改密码</button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script setup>
import { ref, getCurrentInstance, reactive } from 'vue';
import store from '@/store/index.js';
import UserService from '@/service/user/index.js';
const { proxy } = getCurrentInstance()

let isLoading = ref(false);
let dialogVisible = ref(false);

const modifyUsernameFormData = reactive({
    username: null
})

const modifyPasswordFormData = reactive({
    newPassword: null,
	repeatPassword: null,
	email: null
})

const props = defineProps({
	email: {
		type: String
	}
})

/**
 * 修改用户名
 */
const modifyUsername = async () => {
    isLoading.value = true;
    if (modifyUsernameFormData.username) {
		Object.assign(modifyUsernameFormData, {
			"email": props.email
		})
		const res = await UserService.postModifyUsernameAPI(modifyUsernameFormData);
		if (res.code == 200) {
			store.commit('UserStore/updateUser', res.data);
            location.reload();
            proxy.$message.success({
                message: '修改成功'
            })
		} else  {
            proxy.$message.error({
                message: res.message
            })
		}
	} else {
        proxy.$message.error({
            message: '用户名过长或为空'
        })
	}
    isLoading.value = false;
}

/**
 * 修改密码
 */
const modifyPassword = async () => {
    isLoading.value = true;
    if (modifyPasswordFormData.newPassword == modifyPasswordFormData.repeatPassword) {
		Object.assign(modifyPasswordFormData, {
			"email": props.email
		})
		const res = await UserService.postModifyPasswordAPI(modifyPasswordFormData);
		if (res.code == 200) {
			store.commit('UserStore/removeUser');
			location.reload();
            proxy.$message.success({
                message: '修改成功'
            })
		} else {
			proxy.$message.error({
                message: res.message
            })
		}
	} else {
		proxy.$message.error({
            message: '密码不相等'
        })
	}
    isLoading.value = false;
}


const open = () => {
    dialogVisible.value = true;
};
 

const close = () => {
    dialogVisible.value = false;
}

defineExpose({
	open,
    close
});
</script>

<style scoped>

</style>