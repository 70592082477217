<template>
    <div>
        <el-form :model="form" label-width="0" style="padding: 10px 30px 0px 30px;">
            <el-form-item size="large">
                <el-input style="width: 300px;" v-model="form.email" 
                    placeholder="请输入邮箱" autocomplete="off" />
            </el-form-item>
            <el-form-item size="large">
                <el-input style="width: 300px;" @keyup.enter="handleSearchEnter()" 
                    v-model="form.password" type="password" placeholder="请输入密码" autocomplete="off" />
            </el-form-item>
        </el-form>
        <div class="button-box">
            <button @click="login" style="font-size: 18px;">登录</button>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref, getCurrentInstance } from "vue";
import store from '@/store/index.js'
import UserService from '@/service/user/index.js';

const form = reactive({
    email: null,
    password: null
})

const { proxy } = getCurrentInstance()

const login = async () => {
    const res = await UserService.postUserLoginAPI(form);
    if (res.code == 200) {
        store.commit('UserStore/updateUser', res.data);
        proxy.$message.success({ // success、info、warning
            message: '登录成功',
        })
        proxy.$router.push('/admin'); // 非管理用户哪怕从这里登录，最后也会跳到前台
    } else {
        proxy.$message.info({ // success、info、warning
            message: res.message
        })
    }
}

const handleSearchEnter = () => {
    login();
}

</script>

<style>

</style>