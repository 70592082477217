import request from "@/util/request.js"

/**
 * 获取文章列表
 * @returns 
 */
const getBlogArticlePageAPI = (data) => {
    return request({
        method: 'GET',
        url: '/api/blog/article/page',
        params: data
    });
}

/**
 * 根据文章id获取文章信息
 * @param {*} article_id 
 */

const getBlogArticleDetailAPI = (articleId) => {
    return request({
        method: 'GET',
        url: `/api/blog/article/detail/${articleId}`
    });
}

/**
 * 根据 文章 ID 获取 评论列表
 * @param {*} blogId 
 * @returns 
 */
const getBlogArticleCommentListAPI = (articleId) => {
    return request({
        method: 'GET',
        url: `/api/blog/article/comment/list/${articleId}`
    })
}

/**
 * 更新点赞数据
 * @param {*} articleId 
 * @param {*} email 
 */
const getUpdateLikeAPI = (articleId, email) => {
    return request({
        method: 'GET',
        url: `/api/blog/article/like/update/${articleId}/${email}`
    })
}

/**
 * 检查用户对某文章点赞情况
 * @param {*} articleId 
 * @param {*} email 
 * @returns 
 */
const getCheckLikeAPI = (articleId, email) => {
    return request({
        method: 'GET',
        url: `/api/blog/article/like/check/${articleId}/${email}`
    })
}

/**
 * 发送评论
 * @param {*} data 
 * @returns 
 */
const postSendCommentAPI = (data) => {
    return request({
        method: 'POST',
        url: '/api/blog/article/comment/send',
        data: data
    })
}

/**
 * 更新文章信息
 * @param {*} data 
 * @returns 
 */
const postUpdateBlogArticleAdminAPI = (data) => {
    return request({
        method: 'POST',
        url: `/admin/blog/article/update`,
        data: data   
    })
}

/**
 * 创建文章
 * @param {*} data 
 */
const postCreateBlogArticleAdminAPI = (data) => {
    return request({
        method: 'POST',
        url: '/admin/blog/article/create',
        data: data
    })
}

/**
 * 管理员获取评论分页
 * @param {*} data 
 * @returns 
 */
const getBlogArticleCommentPageAdminAPI = (data) => {
    return request({
        method: 'GET',
        url: '/admin/blog/article/comment/page',
        params: data
    })
}
 
export default {
    getBlogArticlePageAPI,
    getBlogArticleDetailAPI,
    getBlogArticleCommentListAPI,
    getUpdateLikeAPI,
    getCheckLikeAPI,
    getBlogArticleCommentPageAdminAPI,

    postSendCommentAPI,
    postUpdateBlogArticleAdminAPI,
    postCreateBlogArticleAdminAPI
}


