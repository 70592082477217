<template>
    <el-dialog v-model="dialogVisible" width="650">
        <template #header>
            <span style="font-size: 18px;">创建/编辑
                <span style="color: red;">（点击确定后才会保存修改）</span>
            </span>
        </template>
        <el-form :model="form" label-width="50" :size="'large'" label-position="left" style="padding: 10px 30px 10px 30px;">
            <el-form-item>
                <span style="margin-right: 10px; font-size: 16px; color: #000;">「标题」</span>
                <el-input style="width: 350px;" v-model="form.title" placeholder="请输入试题的标题"></el-input>
            </el-form-item>
            <el-form-item>
                <span style="margin-right: 10px; font-size: 16px; color: #000;">「启用」</span>
                <el-switch v-model="form.enabled" />
            </el-form-item>
            <el-form-item v-if="form.id != null">
                <span style="margin-right: 10px; font-size: 16px; color: #000;">「标签」</span>
                <my-problem-tag-cascader 
                    :form="form"
                    @handleChange="handleTagChange">
                </my-problem-tag-cascader>
            </el-form-item>
            <el-form-item>
                <span style="margin-right: 10px; font-size: 16px; color: #000;">「描述」</span>
                <el-input type="textarea" style="width: 350px;" v-model="form.description" placeholder="请输入试题的描述" />
            </el-form-item>
        </el-form>

        <template #footer>
            <div>
                <el-button @click="close">取消</el-button>
                <el-button @click="confirm" :loading="confirmButtonLoding" type="primary">确定</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script setup>
import { reactive, ref, getCurrentInstance, watch, onMounted } from "vue";
import ProblemService from '@/service/problem/index.js';
import store from '@/store/index.js';

const { proxy } = getCurrentInstance()

const confirmButtonLoding = ref(false);

let dialogVisible = ref(false);

const tagIdList = reactive([]);

const props = defineProps({
    form: {
        type: Object
    }
})

const form = reactive({
    id: null,
    title: '',
    enabled: false,
    description: '',
    tagIdList: [] // 接收 cascader 变化，然后赋值给 tagIdList
});

watch(
    () => props.form,
    (value, oldValue) => {
        Object.assign(form, value);
    },
    { immediate: true, deep: true } // deep 设为 true 可以监听到 Object 内的属性变化
)

onMounted(async () => {
    
})


/**
 * 确认修改
 */
const confirm = async () => {
    confirmButtonLoding.value = true;
    const res = await ProblemService.saveProblemPaperAPI({
        id: form.id,
        title: form.title,
        enabled: form.enabled,
        description: form.description,
        tagIdList: tagIdList
    })
    confirmButtonLoding.value = false;
    if (res.code == 200) {
        proxy.$message.success({
            message: '保存成功',
        })
        location.reload();
    } else {
        proxy.$message.info({
            message: res.message,
        })
    }
}

/**
 * 处理选择标签变化
 * @param value 
 */
const handleTagChange = async(value) => {
    tagIdList.length = 0;
    for (let item of value) {
        tagIdList.push(item);
    }
}


const open = async () => {
    dialogVisible.value = true;
};
 

const close = () => {
    dialogVisible.value = false;
}

defineExpose({
	open,
    close
});
</script>

<style>

</style>