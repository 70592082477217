<template>
    <el-dialog v-model="dialogVisible" title="创建/编辑" width="400">
        <el-form :model="form" label-width="70" style="padding: 10px 30px 10px 30px;">
            <el-form-item label="标题">
                <el-input style="width: 300px;" v-model="form.title" placeholder="输入标题" autocomplete="off" />
            </el-form-item>
            <el-form-item label="描述">
                <el-input type="textarea" style="width: 300px;" v-model="form.description" placeholder="输入描述" autocomplete="off" />
            </el-form-item>
        </el-form>

        <template #footer>
            <div>
                <el-button @click="close">取消</el-button>
                <el-button @click="confirm" :loading="confirmButtonLoding" type="primary">确定</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script setup>
import { reactive, ref, getCurrentInstance, watch } from "vue";
import ProblemService from '@/service/problem/index.js';


const { proxy } = getCurrentInstance()

const confirmButtonLoding = ref(false);

let dialogVisible = ref(false);

const props = defineProps({
    form: {
        type: Object
    }
})

const form = reactive({
    id: null,
    title: null,
    description: null,
});

watch(
    () => props.form,
    (value, oldValue) => {
        Object.assign(form, value);
    },
    { immediate: true, deep: true } // deep 设为 true 可以监听到 Object 内的属性变化
)

const confirm = async () => {
    confirmButtonLoding.value = true;
    const res = await ProblemService.saveProblemTagAdminAPI(form);
    confirmButtonLoding.value = false;
    if (res.code == 200) {
        proxy.$message.success({
            message: '保存成功',
        })
        location.reload();
    } else {
        proxy.$message.info({
            message: res.message,
        })
    }
    
}


const open = () => {
    dialogVisible.value = true;
};
 

const close = () => {
    dialogVisible.value = false;
}

defineExpose({
	open,
    close
});
</script>

<style>

</style>