<template>
    <div class="rotate-img-box" >
        <img v-if="props.avatar" :src="props.avatar" @click="open" />
        <img v-else src="../../../assets/logo.png" @click="open" />
        <p style="margin-top: 20px; color: rgba(0,0,0,.5); width: 200px;">点击上方头像修改信息</p>
    </div>
	<modify-info ref="modifyinfo" :email="props.email"></modify-info>
</template>

<script setup>
// 引入组件
import ModifyInfo from './components/modify-info.vue'
// 引入vue
import { getCurrentInstance, ref, watch } from 'vue';

const modifyinfo = ref();

const props = defineProps({
    size: {
        type: String
    },
	avatar: {
		type: String
	},
	email: {
		type: String
	}
})

const size = ref('');

watch(
    () => props.size,
    (value, oldValue) => {
        size.value = value;
    }, 
    { immediate: true }
)


const { proxy } = getCurrentInstance();
const open = () => {
	if (props.email) {
		modifyinfo.value.open();
	}
}
</script>

<style scoped>
.rotate-img-box {
    height: 100%; 

    display: flex; 
    flex-direction: column;
    align-items: center; 
    justify-content: center;
}

.rotate-img-box img{
    width: v-bind(size);
    height: v-bind(size);

    border: #000 solid 0px;
    display: block;
    border-radius: 50%;
    /* transition: all 1.0s; */
    object-fit:cover;

    animation: rotate 10s linear infinite;

    caret-color: transparent;
}

@media screen and (max-width: 1080px) {


.rotate-img-box img {
    width: 300px;
    height: 300px;
}
}

/* .rotate-img-box img:hover{
    transform: rotate(360deg);
    caret-color: transparent;
} */

@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

</style>