import request from "@/util/request";

/**
 * 发送验证码
 * @param {*} email 
 * @returns 
 */
export default async (email) => {
	return request({
		method: 'GET',
		url: '/api/sms/email/send/' + email
	})
}