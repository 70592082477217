<template>
    <div class="my-admin-header-box">
        <div class="nav-item-box" style="margin-left: auto;">
            <!-- el-dropdown 相当于一层 div -->
            <el-dropdown size="large" style="height: 100%;"> 
                <div class="user-item">
                    <div class="router-link-item"  @click="handlePerson">
                        <img v-if="user.avatar" :src="user.avatar" style="width: 30px; height: 30px; object-fit:cover;  border-radius: 50%;"/>
                        <img v-else src="../../../../assets/logo.png" style="width: 30px; height: 30px; object-fit:cover; border-radius: 50%;"/>
                        <div style="margin-left: 5px;">
                            <p class="p-title-kaiti" style="font-size: 18px; max-width: 100px; color: #000;">
                                {{ user.username }}
                            </p>
                        </div>
                    </div>
                </div>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item>
                            <div>
                                <p @click="logout" style="caret-color: transparent;">注销</p>
                            </div>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
                
        </div>  
        
    </div>
</template>

<script setup>
import { ref, reactive, onMounted, getCurrentInstance } from 'vue'
import store from '@/store/index.js'

const { proxy } = getCurrentInstance();
const user = reactive({
    email: null,
    username: null,
    avatar: null,
    token: null
})

onMounted(() => {
    Object.assign(user, store.getters['UserStore/getUser']);

})

/**
 * 注销
 */
const logout = () => {
    store.commit('UserStore/removeUser');
    proxy.$router.push('/admin/login');
}
</script>

<style scoped>


.nav-item-box{
    height: 100%;
    width: 10%;

    padding: 0 10px 0 10px;

}

.router-link-item {
    height: 100%; 

    display: flex; 
    
    align-items: center; 
    justify-content: center;
}

.user-item {
    height: 100%; 
    caret-color: transparent;
}
.user-item > div:hover {
    color:#06b1f5;
    text-decoration: none;

    cursor: pointer;
    opacity: .8;
}

/* 隐藏黑色外边框 */
:deep(.el-tooltip__trigger:focus-visible) {
    outline: unset;
}
</style>