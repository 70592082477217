<template>
    <el-dialog v-model="dialogVisible" title="编辑" :width="600">
        <el-form :model="form" label-width="70" style="padding: 10px 30px 10px 30px;">
            <el-form-item label="题目类型">
                <el-input v-model="form.title" :disabled="true"></el-input>
            </el-form-item>
            <!-- el-tree 写在 el-form 内是为了好看 -->
            <!-- 根节点很特殊，不提供删根节点的功能 -->
            <!-- 编辑框内的el-tree，展开所有节点，并且带有编辑、删除、添加按钮，点击node不会出发click-node事件 -->
            <el-tree
                :default-expand-all="true"
                :expand-on-click-node="false"
                style="max-width: 600px; color: #000; font-size: 16px; margin-top: 10px;"
                :data="treeList"
                :props="treeProps">
                <template #default="{ node, data }">
                    <span>
                        <!-- {{ data.title }} -->
                        <el-input v-model="data.title"></el-input>
                    </span>
                    
                    <span class="click-a-box" style="margin-left: auto; display: flex;">
                        <a @click="handleAddNode(data)">添加</a>
                        <el-popconfirm  
                            v-if="data.level > 0"
                            confirm-button-text="确定"
                            cancel-button-text="取消"
                            cancel-button-type="danger"
                            :placement="'right'"
                            width="auto"
                            :title="`确定要删除 「${data.title}」 吗？`"
                            @confirm="handleDeleteNode(node, data)">
                            <template #reference>
                                <a style="margin-left: 10px;">删除</a>
                            </template>
                        </el-popconfirm>   
                    </span>
                </template>
            </el-tree>
        </el-form>

        <template #footer>
            <div class="button-box">
                <button @click="close" style="font-size: 16px;">取消</button>
                <button @click="confirm" style="margin-left: 10px; font-size: 16px;">确定</button>
            </div>
        </template>
    </el-dialog>
</template>

<script setup>
import { reactive, ref, getCurrentInstance, onMounted, watch } from "vue";
import store from '@/store/index.js'
import UserService from '@/service/user/index.js';

let dialogVisible = ref(false);

const form = reactive({
    title: null,
    email: null,
    password: null
})

const treeProps = reactive({});
const treeList = reactive([]);

const props = defineProps({
    title: String,
    tree: Object
})

const { proxy } = getCurrentInstance()

onMounted(() => {
    
})
 
const confirm = async() => {
    
}

/**
 * 添加节点
 * @param data 
 */
const handleAddNode = async(data) => {
    const newNode = {
        id: null,
        title: '新节点',
        level: data.level + 1,
        subjectId: data.subjectId,
        tagId: data.tagId,
        children: []
    }
    if (data.children == null || data.children == undefined) {
        data.children = []
    }
    data.children.push(newNode);
    console.log('data:', data);
}

/**
 * 删除节点
 * @param node 
 * @param data 
 */
const handleDeleteNode = async(node, data) => {
    const parent = node.parent
    const children = parent.data.children || parent.data;
    const index = children.findIndex((d) => d.id === data.id)
    children.splice(index, 1);
}


watch(
    () => props.title,
    (value, oldValue) => {
        Object.assign(form, {
            title: value
        })
    },
    { immediate: true, deep: true }
)

watch(
    () => props.tree.props,
    (value, oldValue) => {
        Object.assign(treeProps, value)
    },
    { immediate: true, deep: true }
)

watch(
    () => props.tree.list,
    (value, oldValue) => {
        treeList.length = 0;
        for (let item of value) treeList.push(item);
    },
    { immediate: true, deep: true }
)


const open = () => {
    dialogVisible.value = true;
};
 

const close = () => {
    dialogVisible.value = false;
}

defineExpose({
	open,
    close
});
</script>

<style scoped>
.el-tree >>> .el-tree-node__content {
    padding: 20px 0px 20px 0px;
}
</style>