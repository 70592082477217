
// const env = 0;
const env = 1;

// const httpProtocol = env == 0 ? 'http' : 'https';
// const serverIp = env == 0 ? '10.78.212.75' : 'varitall.top';

const dev = {
    httpProtocol: 'http',
    serverIp: 'localhost'
}

const pro = {
    httpProtocol: 'https',
    serverIp: 'varitall.top'
}

export default env == 0 ? dev : pro;