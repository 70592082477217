<template>
    <el-dialog v-model="dialogVisible" title="创建/编辑" width="400">
        <el-form :model="form" label-width="70" style="padding: 10px 30px 10px 30px;">
            <el-form-item label="学科ID">
                <el-select v-model="form.subjectId" @change="getMenuIdList();">
                    <el-option v-for="item in subjectList" :key="item.id" :value="item.id">
                        {{ item.grade + item.title }}
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="父ID">
                <el-select v-model="form.parentId">
                    <el-option v-for="item in menuList" :key="item.id" :value="item.id">
                        {{ item.title }}
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="标签ID">
                <el-select v-model="form.tagId" >
                    <el-option v-for="item in tagList" :key="item.id" :value="item.id">
                        {{ item.title }}
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="标题">
                <el-input style="width: 300px;" v-model="form.title" placeholder="输入标题" autocomplete="off" />
            </el-form-item>
            <el-form-item label="描述">
                <el-input type="textarea" style="width: 300px;" v-model="form.description" placeholder="输入描述" autocomplete="off" />
            </el-form-item>
        </el-form>

        <template #footer>
            <div>
                <el-button @click="close">取消</el-button>
                <el-button @click="confirm" :loading="confirmButtonLoding" type="primary">确定</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script setup>
import { reactive, ref, getCurrentInstance, watch } from "vue";
import ProblemService from '@/service/problem/index.js';


const { proxy } = getCurrentInstance()

const confirmButtonLoding = ref(false);

let dialogVisible = ref(false);

const props = defineProps({
    form: {
        type: Object
    }
})

const form = reactive({
    id: null,
    parentId: null,
    subjectId: null,
    tagId: null,
    title: null,
    description: null
});

const menuList = reactive([
    {
        id: 0,
        title: '「 无 」'
    }
]);

const subjectList = reactive([]);

const tagList = reactive([
    {
        id: 0,
        title: '「 无 」'
    }
]);

watch(
    () => props.form,
    (value, oldValue) => {
        Object.assign(form, value);
    },
    { immediate: true, deep: true } // deep 设为 true 可以监听到 Object 内的属性变化
)

const confirm = async () => {
    confirmButtonLoding.value = true;
    const res = await ProblemService.saveProblemMenuAdminAPI(form);
    confirmButtonLoding.value = false;
    if (res.code == 200) {
        proxy.$message.success({
            message: '保存成功',
        })
        location.reload();
    } else {
        proxy.$message.info({
            message: res.message,
        })
    }
    
}


const getSubjectIdList = async() => {
    const res = await ProblemService.getProblemSubjectIdListAPI();
    if (res.code == 200) {
        subjectList.length = 0;
        for (let item of res.data) subjectList.push(item);
    } else {
        proxy.$message.error({
            message: res.message
        })
    }
}


const getMenuIdList = async() => {
    if (form.subjectId == null) {
        return;
    }
    menuList.length = 1;
    const res = await ProblemService.getProblemMenuIdListAPI(form.subjectId);
    if (res.code == 200) {
        if (res.data.length != 0) {
            for (let item of res.data) menuList.push(item);
        }
    } else {
        proxy.$message.error({
            message: res.message
        })
    }
}


const getTagIdList = async() => {
    const res = await ProblemService.getProblemTagIdListAPI();
    if (res.code == 200) {
        tagList.length = 1;
        for (let item of res.data) tagList.push(item);
    } else {
        proxy.$message.error({
            message: res.message
        })
    }
}

const open = () => {
    dialogVisible.value = true;
    getSubjectIdList();
    getMenuIdList();
    getTagIdList();
};
 

const close = () => {
    dialogVisible.value = false;
}

defineExpose({
	open,
    close
});
</script>

<style>

</style>