// Api
import checkTokenAPI from "./api/checkTokenAPI.js"
import getEmailCodeAPI from "./api/getEmailCodeAPI.js"
import getModifyPasswordEmailCodeAPI from "./api/getModifyPasswordEmailCodeAPI.js"
import postForgetPasswordAPI from "./api/postForgetPasswordAPI.js"
import postModifyUsernameAPI from "./api/postModifyUsernameAPI.js"
import postModifyPasswordAPI from "./api/postModifyPasswordAPI.js"
import postUserLoginAPI from "./api/postUserLoginAPI.js"
import postUserRegisterAPI from "./api/postUserRegisterAPI.js"
import setUserAvatarByQQAPI from "./api/setUserAvatarByQQAPI.js"

// Admin
import getUserPageAdminAPI from "./admin/getUserPageAdminAPI.js"
import postSaveUserAdminAPI from "./admin/postSaveUserAdminAPI.js"
import postDeleteUserByIdAdminAPI from "./admin/postDeleteUserByIdAdminAPI.js"



const UserService = {
    // Api
	checkTokenAPI,
    getEmailCodeAPI,
    getModifyPasswordEmailCodeAPI,
    postForgetPasswordAPI,
    postModifyPasswordAPI,
    postModifyUsernameAPI,
    postUserLoginAPI,
    postUserRegisterAPI,
    setUserAvatarByQQAPI,
    // Admin
    getUserPageAdminAPI,
    postSaveUserAdminAPI,
    postDeleteUserByIdAdminAPI
}

export default UserService;

