<template>
    <div>
        <el-menu 
            :default-active="'1'" 
            class="el-menu-box"
            @open="handleOpen" 
            @close="handleClose" 
            @select="handleSelect"
            :default-openeds="['2', '9', '11', '5']"> 
            <div v-for="item in menuList" :key="item.id">
                <div v-if="item.path">
                    <router-link :to="item.path">
                        <el-menu-item :index="item.id">
                            <span>{{ item.title }}</span>
                        </el-menu-item>
                    </router-link>
                </div>
                <div v-else>
                    <el-sub-menu :index="item.id">
                        <template #title>
                            <span>{{ item.title }}</span>
                        </template>
                        <div v-for="subItem in item.subMenuList" :key="subItem.id">
                            <div v-if="subItem.path">
                                <router-link :to="subItem.path">
                                    <el-menu-item :index="subItem.id">
                                        <span>{{ subItem.title }}</span>
                                    </el-menu-item>
                                </router-link>
                            </div>
                            <div v-else>
                                <el-menu-item :index="subItem.id">
                                    <span>{{ subItem.title }}</span>
                                </el-menu-item>
                            </div>
                        </div>
                    </el-sub-menu>
                </div>
            </div>
        </el-menu>
    </div>
</template>

<script setup>
import { onMounted, reactive } from 'vue';
import store from '@/store/index.js';

const menuList = reactive([
    {
        id: '1',
        title: '首页',
        path: '/admin/home'
    },
    {
        id: '2',
        title: '用户管理',
        path: null,
        subMenuList: [
            {
                id: '3',
                title: '用户列表',
                path: '/admin/user/list'
            },
            {
                id: '4',
                title: '用户编辑',
                path: '/admin/user/edit'
            }
        ]
    },
    {
        id: '9',
        title: '博客管理',
        path: null,
        subMenuList: [
            {
                id: '10',
                title: '评论列表',
                path: '/admin/article/comment/list'
            }
        ]
    },
    {
        id: '11',
        title: '题库管理',
        path: null,
        subMenuList: [
            {
                id: '12',
                title: '标签列表',
                path: '/admin/problem/tag/list'
            },
            {
                id: '13',
                title: '目录列表',
                path: '/admin/problem/menu/list'
            },
            {
                id: '14',
                title: '学科列表',
                path: '/admin/problem/subject/list'
            }
        ]
    },
    {
        id: '5',
        title: '主题切换',
        path: null,
        subMenuList: [
            {
                id: '6',
                title: '蓝色风格',
                path: null
            },
            {
                id: '7',
                title: '黄色风格',
                path: null
            },
            {
                id: '8',
                title: '白色风格',
                path: null
            }
        ]
    }
])


const handleSelect = (index, indexPath, item) => {
    // console.log('index:', index);
    // console.log('indexPath:', indexPath);
    // console.log('item:', item);
    if (indexPath.length == 2) {
        for (let i = 0; i < menuList.length; ++ i) {
            if (menuList[i].path == null) {
                for (let j = 0; j < menuList[i].subMenuList.length; ++ j) {
                    const myHeader = document.getElementsByClassName('my-admin-header-box')[0];
                    const myMain = document.getElementsByClassName('my-admin-main-box')[0];
                    if (indexPath[1] == menuList[i].subMenuList[j].id &&  menuList[i].subMenuList[j].title == '蓝色风格') {
                        // console.log(i, j, '蓝色风格');
                        myHeader.classList.remove('my-admin-header-box-yellow');
                        myHeader.classList.add('my-admin-header-box-blue');

                        myMain.classList.remove('my-admin-main-box-yellow');
                        myMain.classList.add('my-admin-main-box-blue');
                    }
                    if (indexPath[1] == menuList[i].subMenuList[j].id &&  menuList[i].subMenuList[j].title == '黄色风格') {
                        // console.log(i, j, '黄色风格');
                        myHeader.classList.remove('my-admin-header-box-blue');
                        myHeader.classList.add('my-admin-header-box-yellow');

                        myMain.classList.remove('my-admin-main-box-blue');
                        myMain.classList.add('my-admin-main-box-yellow');
                    }
                    if (indexPath[1] == menuList[i].subMenuList[j].id &&  menuList[i].subMenuList[j].title == '白色风格') {
                        // console.log(i, j, '白色风格');
                        myHeader.classList.remove('my-admin-header-box-blue');
                        myHeader.classList.remove('my-admin-header-box-yellow');

                        myMain.classList.remove('my-admin-main-box-blue');
                        myMain.classList.remove('my-admin-main-box-yellow');
                    }
                }
            }
        }
    }
}
const handleOpen = (key, keyPath) => {
    // console.log(key, keyPath)
}
const handleClose = () => {
    
}
</script>

<style scoped>
.el-menu-box {
    caret-color: transparent; 
    border-right: none;
}

.el-menu-box span, .el-menu-box a {
    font-size: 16px;
    font-family: Microsoft Yahei;
}

.el-menu-box a:link,
.el-menu-box a:visited {
    text-decoration: none;
}

</style>