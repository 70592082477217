import request from "@/util/request.js"


/**
 * 用户忘记的修改密码
 */
export default async (data) => {
	return request({
	    method: 'POST',
	    url: '/api/core/user/forgetPassword',
	    data: data
	})
}