<template>
    <div class="toolBarBox toolBarBox-fixed">
        <div class="toolbox">
            <div class="toolbox-left">
                <img v-if="user.avatar == null" src="../../../../static/blog/tmpAvatar.png" 
                    style="width: 40px; height: 40px; object-fit: cover; border-radius: 50%; margin-left: 20px;">
                <img v-else :src="user.avatar"
                    style="width: 40px; height: 40px; object-fit: cover; border-radius: 50%; margin-left: 20px;">
            </div>
            <div class="toolbox-right"  v-loading.fullscreen.lock="isLoading">
                <el-popover placement="top" trigger="hover" width="150px" style="">
                    <div style="display: flex; justify-content: center; align-items: center;">
                        <span style="font-size: 20px;" > -- 点赞 -- </span>
                    </div>
                    <template #reference>
                        <button type="button" @click="handleLike"
                        style="margin-top: 5px; margin-right: 20px; cursor: pointer; background-color: transparent; border: none;" >
                            <img v-if="isLike" src='../../../../static/blog/isLike.png'  style="width: 30px; height: auto;">
                            <img v-if="!isLike" src='../../../../static/blog/noLike.png' style="width: 30px; height: auto;">
                        </button>
                    </template>
                </el-popover>
                <!-- <span style="margin-left: 5px; text-align: center; color: rgb(106, 108, 108);">{{ blog.likeNum ?  blog.likeNum : 0 }}</span> -->

                <el-popover placement="top" trigger="hover" width="150px" style="">
                    <div style="display: flex; justify-content: center; align-items: center;">
                        <span style="font-size: 20px;" > -- 评论 -- </span>
                    </div>
                    <template #reference>
                        <button type="button" @click="handleComment"
                            style="margin-right: 20px; margin-top: 6px; cursor: pointer; background-color: transparent; border: none;">
                            <img src='../../../../static/blog/comment.png'  style="width: 30px; height: auto;">
                        </button>
                    </template>
                </el-popover>
                
                <el-popover placement="top-end"  width="500px" trigger="hover">
                    <div style="display: flex; flex-direction: column; height: 100%;">
                        <span style="font-size: 30px; height: 40px; margin-top: 10px; display: flex; justify-content: center; align-items: center;">
                            <img v-for="item in [1, 2, 3]" :key="item" src="../../../../static/blog/flower.png" style="width: 25px; height: auto;">
                            <b style="margin: 0px 20px 0px 20px;">支持作者</b>
                            <img v-for="item in [4, 5, 6]" :key="item" src="../../../../static/blog/flower.png" style="width: 25px; height: auto;">
                        </span>
                        <el-divider></el-divider>
                        <img src='../../../../static/blog/wechatPay.png' style="width: 500px;">
                    </div>
                    <template #reference>
                        <!-- el-popover 会将带有 slot="reference"或v-popover指向ref 的控件识别为触发控件 -->
                        <button type="button" @click="handleGiveMoney" class="toolbox-right-comment-wechat" 
                            style="margin-top: 3px; margin-right: 20px; cursor: pointer; background-color: transparent; border: none;">
                            <img src='../../../../static/blog/giveMoney.png'  style="width: 30px; height: auto;">
                        </button>
                    </template>
                </el-popover>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, onMounted } from 'vue';
import store from '@/store/index.js'
import UserService from '@/service/user/index.js';
import BlogService from '@/service/blog/index.js';

const { proxy } = getCurrentInstance();
let isLoading = ref(false);
const isLike = ref(false);
const articleId = ref(null);
const user = reactive({
    email: null
})

onMounted(() => {
    Object.assign(user, store.getters['UserStore/getUser']);
    articleId.value = proxy.$route.params.articleId;
    getArticleLikeInfo();
})


/**
 * 处理点赞
 */
const handleLike = async () => {
    isLoading.value = true;
    // 校验 token
    const checkToken = await UserService.checkTokenAPI();
    if (checkToken.code != 200) {
        proxy.$message.warning({
            message: '身份信息过期，请重新登录'
        })
        isLoading.value = false;
        return;
    }
    // 更新点赞
    const res = await BlogService.getUpdateLikeAPI(articleId.value, user.email);
    if (res.code == 200) {
        if (res.data == 1) {
            isLike.value = true;
        } else if (res.data == 0) {
            isLike.value = false;
        }
        proxy.$message.success({
            message: res.message
        })
    } else {
        proxy.$message.error({
            message: res.message
        })
    }
    isLoading.value = false;
}

const emit = defineEmits(['handleComment'])
/**
 * 打开评论区
 */
const handleComment = () => {
    emit('handleComment');
}

const handleGiveMoney = () => {

}


/**
 * 获取点赞情况
 */
const getArticleLikeInfo = async () => {
    const res = await BlogService.getCheckLikeAPI(articleId.value, user.email);
    if (res.code == 200) {
        isLike.value = res.data;
    }
}


</script>

<style scoped>
.toolBarBox {
    width: 50vw;
    height: 64px;

    border-radius: 5px;
    background-color: white;

    margin-top: 5px;

    /* 不能设置为relative，包括其下的子控件，都不能设为relative，否则会挡住header */
    /* position: relative; */
    
    /* x offset，y offset，r，red */
    box-shadow: 0 0 5px 1px #999;
    /* box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.1); */
}

.toolBarBox-fixed {
    width: 1000px;

    position: fixed;
    z-index: 999;
    bottom: 0;
}

.toolbox {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;

}

.toolbox-left {
    width: 30%;
    height: 100%;

    align-items: center;

    display: flex;
    /* position: relative; */
}

.toolbox-right {
    /* width: 70%; */
    height: 100%;

    align-items: center;
    display: flex;

    margin-left: auto;
    /* position: relative; */

    caret-color: transparent;
}



@media screen and (max-width: 1080px) {
.toolbox-right-comment-wechat {
    display: none;
}
.toolBarBox-fixed {
    width: 95vw;

    position: fixed;
    z-index: 999;
    bottom: 0;
}

}

</style>