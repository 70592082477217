import { createApp } from 'vue'
import App from './App.vue'
// 引入 router
import router from './router'
// 引入 store
import store from './store'
// 引入 ElementPlus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 引入 自定义全局 css
import '@/assets/global.css'
// 引入 request
import request from '@/util/request.js'
// 引入 自定义组件
import componentList from './components'
// 引入 播放器
import vue3videoPlay from "vue3-video-play"; // 引入组件
import "vue3-video-play/dist/style.css"; // 引入css
// 引入 Vant
// import Vant from 'vant';

let vue = createApp(App).use(store).use(router).use(ElementPlus).use(vue3videoPlay);

vue.config.globalProperties.$request = request

componentList.forEach(item=>{
    // console.log(item.name, item.compnent);
    vue.component(item.name, item.compnent);   //全局注册了
})

vue.mount('#app')
