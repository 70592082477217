import { createStore } from "vuex";
import UserStore from '@/store/modules/user-store.js'
import ProblemStore from "@/store/modules/problem-store.js";

export default createStore({
	state: {
		
	},
	mutations: {
		
	},
	actions: {
		updateUid(context, payload) {
			setTimeout(() => {
				context.commit('updateUid', payload)
			}, 2000)
		},
		getItem(key) {
		    return uni.getStorageSync(key);
		},
		setItem(key, value) {
		    uni.setStorageSync(key, value);
		},
		removeItem(key) {
		    uni.removeStorageSync(key);
		},
		clearItem() {
		    uni.clearStorageSync();
		}
	},
	getters: {
		
	},
	modules: {
		UserStore,
        ProblemStore
	}
});