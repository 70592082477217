<template>
<div class="my-problem-paper-list-el-card">
    <el-card style="margin: 20px 0px 20px 20px;">
        <template #header>
            <span v-if="tag.id == 0 || tag.id == null">「{{index.title}}」推荐试题</span>
            <span v-else>
                <el-tag type="primary" style="font-size: 16px; padding: 15px 15px 15px 15px;">{{ tag.title }}</el-tag>
                <el-button style="margin-left: 10px;" @click="handleRemoveTag">X</el-button>
            </span>
        </template>
        <div v-if="paperList.length == 0"
            style="padding: 20px 0px 20px 10px;">
            暂无试题，敬请期待
        </div>
        <div v-for="item in paperList" :key="item.id"   class="el-card-paper"
            style="padding: 20px 0px 20px 10px;">
            <el-row>
                <el-col :span="3">
                    <div v-if="String(item.filename).endsWith('doc') || String(item.filename).endsWith('docx')">
                        <img style="max-width: 100px;" :src="'https://varitall.oss-cn-beijing.aliyuncs.com/icon/word-2.png'"/>
                    </div>
                    <div v-if="String(item.filename).endsWith('pdf') || String(item.filename).endsWith('pdf')">
                        <img style="max-width: 100px;" :src="'https://varitall.oss-cn-beijing.aliyuncs.com/icon/pdf-1.png'"/>
                    </div>
                    <div v-if="String(item.filename).endsWith('rar') || String(item.filename).endsWith('zip')">
                        <img style="max-width: 100px;" :src="'https://varitall.oss-cn-beijing.aliyuncs.com/icon/zip-1.png'"/>
                    </div>
                    <div v-if="item.filename == null || item.filename == ''">
                        <img style="max-width: 100px;" :src="'https://varitall.oss-cn-beijing.aliyuncs.com/icon/no-data.png'"/>
                    </div>
                </el-col>
                <el-col :span="16">
                    <div>
                        <span>
                            <el-link :href="item.path" target="_blank" 
                                type="primary" style="font-size: 18px;">{{ item.title }}</el-link>
                        </span>
                    </div>
                    <div style="margin-top: 10px;">
                        <el-tag type="success" v-for="subItem in item.tagIdList" :key="subItem" style="margin-right: 10px;">
                            {{ tagIdMap.get(String(subItem)) != null ? tagIdMap.get(String(subItem)).title : '' }}
                        </el-tag>
                    </div>
                    <div style="margin-top: 10px;">
                       <span style="color:#808080; font-size: 14px;"> 日期：{{ item.createTime }}</span>
                    </div>
                </el-col>
                <el-col :span="4" style="display: flex; align-items: center; justify-content: center;">
                    <el-button type="primary" style="width: 100px;" @click="handleDetail(item.id)">查看</el-button>
                </el-col>
            </el-row>
        </div>
    </el-card>
</div>
</template>

<script setup>
import { getCurrentInstance, onMounted, onUnmounted, reactive, ref } from 'vue'
import store from '@/store/index.js';
import ProblemService from '@/service/problem/index.js';

const {proxy} = getCurrentInstance();

const tagIdMap = new Map();

const user = reactive({
    email: '',
    privilege: 0,
    token: ''
})

const tag = reactive({
    id: 0,
    title: ''
})

const index = reactive({
    title: '初中物理',
    subjectId: 1
})

const paperList = reactive([]);

onMounted(async () => {
    Object.assign(user, store.getters['UserStore/getUser']);
    await getTagIdMap(); // 要放在 getPaperList 前面，否则渲染不出来 title
    await getPaperList();
})

onUnmounted(async () => {
    store.commit('ProblemStore/removeTag', null);
})

/**
 * 查看paper具体页面
 * @param id
 */
 const handleDetail = async(id) => {
    let { href } = proxy.$router.resolve({ path: `/problem/paper/detail/${id}` })
    window.open(href, '_blank')
}


/**
 * 获取对应 tagIdList 的 paperList
 */
const getPaperList = async() => {
    Object.assign(tag, store.getters['ProblemStore/getTag']);
    Object.assign(index, store.getters['ProblemStore/getIndex'])
    const res = await ProblemService.getProblemPaperListByTagIdsAPI({
        subjectId: index.subjectId,
        tagIdList: [(tag.id ? tag.id : 0)]
    });
    if (res.code == 200) {
        paperList.length = 0;
        for (let item of res.data) {
            Object.assign(item, {
                path: `/problem/paper/detail/${item.id}`,
            })
            paperList.push(item);
        }
        proxy.$message.success({
            message: '获取试题成功'
        })
    } else {
        proxy.$message.error({
            message: res.message
        })
    }
}

/**
 * 获取 tag Map
 */
 const getTagIdMap = async() => {
    const res = await ProblemService.getProblemTagIdMapAPI();
    if (res.code == 200) {
        // 返回值是一个对象，要用 in 获取 key（of 是用来遍历数组的）
        for (let key in res.data) {
            const value = res.data[key];
            tagIdMap.set(key, value);
        }
    }
}

/**
 * 关闭标签选择
 */
const handleRemoveTag = async() => {
    store.commit('ProblemStore/removeTag');
    await getPaperList();
}

defineExpose({
    getPaperList
})

</script>

<style scoped>
.my-problem-paper-list-el-card >>> .el-card__body {
    padding: 1px 1px 1px 1px;
}

.el-card-paper:hover{
    outline: 1px solid #409EFF;
}
</style>