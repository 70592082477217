<template>
<div v-clickoutside="hideReplyBtn" >
    <el-drawer v-model="dialogVisible" title="评论区" :direction="elDrawerAttr.direction" :size="elDrawerAttr.size">
        <div id="blogComment">
            <div class="my-comment">
                <div class="my-reply-up">
                    <div class="comment-avatar-box">
                        <img v-if="user.avatar != null" :src="user.avatar" class="comment-avatar">
                        <img v-else src="../../../../static/blog/tmpAvatar.png" class="comment-avatar">
                    </div>
                    
                    <div class="comment-input-box" >
                        <!-- <textarea @focus="showReplyBtn" @blur="hideReplyBtn" maxlength="500" max-rows="5"  placeholder="请输入评论"></textarea> -->
                        <div tabindex="0"  id="inputComment" contenteditable="true" placeholder="总座高见..." 
                            class="comment-input"   @focus="showReplyBtn" @input="onDivInput($event)">
                        </div>
                    </div>
                    
                </div>

                <div class="reply-btn-box" v-show="btnShow">
                    <el-button class="reply-btn" size="default" @click="sendComment()" type="primary">发表评论</el-button>
                </div>
            </div>
            
            <div class="comment-list-box">
                <div v-for="(item, index_1) in commentList" :key="item.id" style="margin-bottom: 30px; margin-left: 50px; margin-right: 50px;">
                    
                    <div class="comment-avatar-box" style="display: flex;">
                        <div style="display: flex; align-items: center; justify-content: center;">
                            <img :src="item.user.avatar" class="comment-avatar">
                        </div>
                        
                        <div class="author-info" style="">
                            <span class="author-name">{{item.user.username}}</span>
                            <span class="author-time" style="color: brown;">{{item.createTime}}[GMT+8]</span>
                        </div>

                        <div class="icon-btn" style="margin-left: auto;">
                            <span @click="showReplyInput(index_1, item.user.email, item.id, item.user.username, item.id)">
                                <i class="iconfont el-icon-s-comment"></i>回复
                            </span>
                            <!-- <i class="iconfont el-icon-caret-top"></i>{{item.like}} -->
                        </div>
                    </div>

                    <div class="talk-box" style="display: flex;">
                        <div class="reply" style="color: #208B31; padding-left: 10px;">{{item.content}}</div>
                        <span v-if="item.replyList.length > 0" @click="showCommentMoreReply(index_1)">更多回复</span>
                    </div>

                    <div class="reply-box" v-show="item.showReply">
                        <div v-for="(subItem) in item.replyList" :key="subItem.id" class="author-title">
                        
                            <div class="comment-avatar-box"  style="display: flex;">
                                <div style="display: flex; align-items: center; justify-content: center;">
                                    <img :src="subItem.user.avatar" class="comment-avatar">
                                </div>
                                
                                <div class="author-info">
                                    <span class="author-name">{{subItem.user.username}}</span>
                                    <span class="author-time"  style="color: brown;">{{subItem.createTime}}[GMT+8]</span>
                                </div>
                                <div class="icon-btn"  style="margin-left: auto;">
                                    <!-- 这里用的是 index_1 和 item.id，因为父id指向的都是同一个根评论 -->
                                    <span @click="showReplyInput(index_1, subItem.user.email, item.id, subItem.user.username, subItem.id)">
                                        <p style="margin-left: auto;">回复</p>
                                    </span>
                                </div>
                            </div>
                            
                            <div style="margin-left: 50px; padding: 10px 20px 0px 10px;">
                                <p>
                                    <span>
                                        回复 
                                        <span style="color: blue;">
                                            {{subItem.parentUser.username}}：
                                        </span>
                                    </span>
                                    <p></p>
                                    <div style="white-space: pre-line; margin-top: 10px; font-size: 16px; color: #208B31; word-wrap: break-word;">
                                        {{subItem.content}}
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div v-show="_inputShow(index_1)" class="my-reply my-comment-reply">
                        <div class="my-reply-up">
                            <div class="comment-avatar-box">
                                <img v-if="user.avatar != null" :src="user.avatar" class="comment-avatar">
                                <img v-else src="../../../../static/blog/tmpAvatar.png" class="comment-avatar">
                            </div>
                            
                            <div class="comment-input-box"  >
                                <!-- <textarea @focus="showReplyBtn" @blur="hideReplyBtn" maxlength="500" max-rows="5"  placeholder="请输入评论"></textarea> -->
                                <div tabindex="0" id="inputComment" contenteditable="true" 
                                    :placeholder="'回复 ' + replyToUsername + ': '" class="comment-input" @input="onDivInput($event)">
                                </div>
                            </div>
                        </div>
                        
                        <div class="reply-btn-box">
                            <el-button class="reply-btn" size="default" @click="sendComment(item.id)" type="primary">发表评论</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-drawer>
</div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, onMounted } from 'vue';
import store from '@/store/index.js'
import { vClickoutside } from '@/util/click-outside.js';
import UserService from '@/service/user/index.js';
import BlogService from '@/service/blog/index.js';

const windowWidth = ref(0);
const elDrawerAttr = reactive({
    direction: 'rtl',
    size: '50%'
})

const dialogVisible = ref(false);
const inputComment = ref(null);
const btnShow = ref(false);
const lastReplyIndex = ref(0);
const lastReplyId = ref(0);
const showReplyList = ref(false);
const replyToCommentId = ref(null);
const replyToEmail = ref(null);
const replyToUsername = ref(null);
const articleId = ref(null);
const commentList = reactive([]);
const user = reactive({
    email: null,
    username: null,
    avatar: null
})
const { proxy } = getCurrentInstance();

onMounted(() => {
    Object.assign(user, store.getters['UserStore/getUser']);
    articleId.value = proxy.$route.params.articleId;
    getCommentList();
    getWindowResize();
    window.addEventListener('resize', getWindowResize);
})


/**
 * 显示按钮
 */
const showReplyBtn = () => {
    btnShow.value = true;
};

const hideReplyBtn = () => {
    btnShow.value = false;
};

/**
 * 获取评论列表
 */
const getCommentList = async () => {
    const res = await BlogService.getBlogArticleCommentListAPI(articleId.value);
    if (res.code == 200) {
        commentList.length = 0;
        for (let i = 0; i < res.data.length; ++ i) {
            commentList.push(res.data[i]);
            Object.assign(commentList[i], {
                inputShow: false,
                showReply: false
            })
        }
        proxy.$message.success({
            message: '获取评论列表成功'
        })
    } else {
        proxy.$message.error({
            message: '获取评论列表失败'
        })
    }
};

/**
 * 获取输入的评论
 * @param {*} e 
 */
const onDivInput = (e) =>  {
    inputComment.value = e.target.innerText;
};

/**
 * 发表评论
 * @param {*} parentId 
 */
const sendComment = async (parentId) => {
    // 校验 token
    const checkToken = await UserService.checkTokenAPI();
    if (checkToken.code != 200) {
        proxy.$message.warning({
            message: '身份信息过期，请重新登录'
        })
        return;
    }
    // 
    if (inputComment.value == '' || inputComment.value == null) {
        proxy.$message.warning({
            message: '评论不能为空'
        })
        return;
    }
    //
    const res = await BlogService.postSendCommentAPI({
        articleId: articleId.value,
        userEmail: user.email,
        parentId: parentId,
        content: inputComment.value,
        replyTo: parentId ? replyToEmail.value : null
    })
    if (res.code == 200) {
        proxy.$message.success({
            message: '发送成功，等待审核'
        })
    } else {
        proxy.$message.error({
            message: res.message
        })
    }
    
};

const showReplyInput = (index, userEmail, parentId, username, id) => {
    if (!commentList[index].inputShow || id != lastReplyId.value) {
        // 将上一个评论输入框设为不可见
        commentList[lastReplyIndex.value].inputShow = false;
        lastReplyIndex.value = index;
        lastReplyId.value = id;

        // 将根评论的输入框设为可见，与 _inputShow(index) 关联
        commentList[index].inputShow = true;

        replyToEmail.value = userEmail;
        replyToUsername.value = username;
        replyToCommentId.value = parentId;
    } else {
        commentList[index].inputShow = false;
    }
};

// 在某条评论下显示输入框
const _inputShow = (index) => {
    return commentList[index].inputShow;
};

// 某条评论的回复
const showCommentMoreReply = (index) => {
    commentList[index].showReply = !commentList[index].showReply;
}

// 获取屏幕尺寸
const getWindowResize = () => {
    windowWidth.value = window.innerWidth;
    if (windowWidth.value > 800) {
        Object.assign(elDrawerAttr, {
            direction: 'rtl',
            size: '50%'
        })
    } else {
        Object.assign(elDrawerAttr, {
            direction: 'btt',
            size: '80%'
        })
    }
}

const open = () => {
    dialogVisible.value = true;
};
const close = () => {
    dialogVisible.value = false;
}
defineExpose({
	open,
    close
});
</script>


<style scoped>
#blogComment {
    /* width: 100%; */
    height: auto;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

}

.my-comment {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    
    align-items: center;
    justify-content: center;

}

.my-reply {
    width: 95%;
    padding-left: 10px;

    display: flex;
    flex-direction: column;

    /* margin-top: 10px; */
}

.my-comment-reply{
    margin-left: 50px;
}
.my-comment-reply .reply-input {
    width: flex;
}

.my-reply-up {
    width: 85%;

    display: flex; 
    flex-direction: row;

    margin: auto 0;

 
    padding: 20px 20px 20px 0px;
}



.comment-avatar {
    width: 40px;
    height: 40px;

    border-radius: 50%;
}

.comment-input-box {
    display: flex; 
    justify-content: center; 
    align-items: center; 

    width: 100%; 
}

.comment-input {
    width: 100%;
    min-height: 40px;
    line-height: 20px;

    resize: none;

    border: 2px solid rgb(217, 215, 215);

    padding: 10px 10px 10px 10px;
    border-radius: 5px;
    margin-left: 20px;

    
    color: #414243;
    background-color: #fff;
                
    
}

.comment-input:empty:before {
    content: attr(placeholder);
}

.comment-input:focus:before {
    content: none;
}
               

.comment-input:focus {
    padding: 10px 10px 10px 10px;

    border: 2px solid rgb(131, 131, 207);

    box-shadow: none;
    outline: none;
}


.reply-btn-box {
    width: 85%;

    padding: 0px 20px 10px 20px;

    display: flex; 
    flex-direction: row;

}
        
.reply-btn {
    margin-left: auto;
}
            

.comment-list-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    
    padding: 10px 20px 200px 20px;
    
}

.comment-avatar-box {
    display: inline-block;
    
}


.author-info {
    display: inline-block;
    width: 60%;
    height: 50px;
    /* background-color: #717374; */
    padding: 0px 20px 0px 20px;
            
}

.author-info > span {
    display: block;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
        
        
.author-name {
    color: #000;
    font-size: 18px;
    font-weight: bold;

    padding-bottom: 5px;
    
}
            
.author-time {
    font-size: 14px;
}

.icon-btn {
    width: 20%;
    padding: 0 !important ;
    
}
        
.icon-btn > span {
    width: 100%;
    cursor: pointer;
}
        
.icon-btn .iconfont {
    margin: 0 5px;
}

.talk-box {
    /* margin: 0 50px; */
    margin-left: 50px;   
    padding: 10px 20px 0px 0px;
}
.talk-box span {
    margin-left: 10px;
    color: #000;
}
.talk-box span:hover {
    cursor: pointer;
    opacity: .8;
    text-decoration: underline;
}
        
.talk-box .reply {
    font-size: 16px;
    color: #000;

    white-space: pre-line; 
    word-wrap: break-word;
}

.author-title {
    padding: 10px;

}

.reply-box {
    width: 80%;
    margin: 10px 0 0 50px;
    background-color: #efefef;
}

@media screen and (max-width: 1080px) {
.comment-list-box {
    padding: 10px 0px 200px 0px;
    
}
}
</style>