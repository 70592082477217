import axios from 'axios'
import Config from '@/config/server-config.js'
import store from '@/store/index.js'


const BASE_URL = `${Config.httpProtocol}://${Config.serverIp}`;

const http = axios.create({
	baseURL: BASE_URL,  // 全局统一加上了 '/api' 前缀，所有接口都会加上'/api'前缀，页面里面写接口的时候就不要加 '/api'了，否则会出现2个'/api'，类似 '/api/api/user'这样的报错，切记！！！
    timeout: 5000,  
})

import router from '@/router/index.js';

// request 拦截器
http.interceptors.request.use(config => {

    if (config.type === 'multipart') {
        config.headers['Content-Type'] = 'multipart/form-data';
    } else {
        config.headers['Content-Type'] = 'application/json;charset=utf-8';
    }

    const { token } = store.getters['UserStore/getUser']
    if (token) {
        config.headers['token'] = token;
    }

    return config
}, error => {
    return Promise.reject(error)
});

// response 拦截器
http.interceptors.response.use(
    response => {
        let res = response; 

        if (response.config.responseType === 'blob') {
            return res
        }
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        return res;
    },
    error => {
        return Promise.reject(error)
    }
)

import { ElMessage } from 'element-plus'
const request = (options) => {
    return new Promise((resolve, reject) => {
        http({
            ...options
        }).then(res => {
            if (res.status == 200) {
                resolve(res.data);
            } else if (res.status == 401) {
                reject(res)
            } else {
                reject(res)
            }
        }).catch(err => {
            if (err.response.status == 429) {
                ElMessage.error('请求过于频繁，请稍后刷新');
            }
            reject(err)
        })
    })
}

export default request;
