<template>
    <div class="app">
        <router-view/>
    </div>
    
</template>

<style>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
</style>
