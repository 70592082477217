
const nullUser = {
	email: null,
	username: null,
	avatar: null,
	token: null
}

export default {
	namespaced: true,
	state: {
		user: JSON.parse(localStorage.getItem('user') || JSON.stringify(nullUser))
	},
	mutations: {
		updateUser(state, userInfo) {
			state.user = userInfo;
			this.commit('UserStore/saveUserToStorage');
		},
		saveUserToStorage(state) {
			localStorage.setItem('user', JSON.stringify(state.user));
		},
		removeUser(state) {
			localStorage.removeItem('user');
			this.commit('UserStore/updateUser', nullUser)
		}
		
	},
	actions: {
		
	},
	getters: {
		getUser(state) {
			return state.user;
		}
	},
};