<template>
<div  ref="scrollArea">
    <el-card style="margin-top: 20px; height: 70vh;">
        <!-- <div v-show="user.privilege >= 5">
            <el-button type="primary" @click="handleOpenEditDialog" style="width: 100px; margin-bottom: 10px;">
                编辑
            </el-button>
        </div> -->
        <div v-show="user.privilege >= 5">
            <el-button type="primary" @click="goToEdit" style="width: 100px; margin-bottom: 10px; font-size: 18px;">
                编辑
            </el-button>
        </div>
        <el-tree
            v-if="tree.list.length > 0"
            :default-expand-all="true"
            style="max-width: 600px ; color: #000; font-size: 16px;"
            :data="tree.list"
            :props="tree.props"
            @node-click="handleNodeClick">
            <template #default="{ data }">
                <span :style="data.tagId == 0 ? data.level == 0 ? treeNodeStyle[0] : treeNodeStyle[1] : treeNodeStyle[2]">{{ data.title }}</span>
            </template>
        </el-tree>
    </el-card>
    <!-- 
        <my-problem-tree-edit-dialog ref="myproblemtreeeditdialog" :title="index.title" :tree="tree"></my-problem-tree-edit-dialog> 
    -->
</div>
</template>

<script setup>
import { getCurrentInstance, onMounted, onUnmounted, reactive, ref } from 'vue'
import store from '@/store/index.js';
import ProblemService from '@/service/problem/index.js';

const myproblemtreeeditdialog = ref();

const scrollArea = ref();

const {proxy} = getCurrentInstance();

const index = reactive({
    title: '',
    subjectId: null
})

const tag = reactive({
    id: null,
    title: null
})

const user = reactive({
    email: '',
    privilege: 0,
    token: ''
})

const tree = reactive({
    props: {
        label: 'title', // label 是 el-tree 的内置树形，映射为我们自定义的 title
        children: 'children',
    },
    list: []
});

const treeNodeStyle = reactive([
    {
        color: '#000',
        'font-weight': 'bold'
    },
    {
        color: '#505050',
    },
    {
        color: '#626aef'
    }
]);


onMounted(async () => {
    Object.assign(user, store.getters['UserStore/getUser']);
    Object.assign(tag, store.getters['ProblemStore/getTag']);
    await getTreeList();

    // 监听滚动事件
    window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
    // 移除滚动事件监听
    window.removeEventListener('scroll', handleScroll)
})


/**
 * 处理滚动
 */
 const handleScroll = () => {   
    const scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop
    // console.log(scrollTop) // 输出滚动条高度
    const offsetTop = scrollArea.value.offsetTop;
    if (scrollTop >= 150) {
        // scrollArea.value.classList.add('my-problem-menu-list-fixed');
        Object.assign(scrollArea.value.style, {
            position: 'relative',
            transform: `translate(-1px, ${scrollTop - 150}px)`
        })
        
    } else {
        // scrollArea.value.classList.remove('my-problem-menu-list-fixed')
        Object.assign(scrollArea.value.style, {
            position: 'relative',
            transform: `translate(-1px, ${0}px)`
        })
    }
}

/**
 * 去编辑页面
 */
const goToEdit = async () => {
    proxy.$router.push('/problem/menu/edit');
}

/**
 * 获取 tree 的 list 属性
 */
const getTreeList = async () => {
    Object.assign(index, store.getters['ProblemStore/getIndex']);
    const res = await ProblemService.getProblemMenuListAPI(index.subjectId);
    if (res.code == 200) {
        tree.list.length = 0;
        tree.list = res.data;
    }
}



const emit = defineEmits(['getPaperList']);
/**
 * 点击树形节点
 * @param data 
 */
 const handleNodeClick = (data) => {
    if (data.tagId == 0) return;
    Object.assign(tag, {
        id: data.tagId,
        title: data.title
    })
    store.commit('ProblemStore/updateTag', tag);
    emit('getPaperList');
}

/**
 * 打开编辑框
 */
const handleOpenEditDialog = () => {
    myproblemtreeeditdialog.value.open();
}


defineExpose({
	getTreeList
});

</script>

<style scoped>
.my-problem-menu-list {
    position: relative;
}

.my-problem-menu-list-fixed {
    position: fixed;
}

.el-tree >>> .el-tree-node__content>.el-tree-node__expand-icon {
    padding: 6px 6px 0px 6px;
}
</style>