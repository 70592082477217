<template>
    <div>
        <el-upload
            ref="upload"
            style="width: 100%;"
            :action="action"
            :limit="1"
            :data="data"
            :multiple="false"
            :method="'post'"
            :name="'multipartFile'"
            :accept="'image/*,.doc,.docx,video/*,audio/*,.pdf,.rar,.zip'"
            :auto-upload="false"
            :show-file-list="true"
            :on-exceed="handleExceed"
            :on-success="handleSuccess"
            :before-upload="handleBeforeUpload">
                <template #trigger>
                    <el-button type="primary">
                        选择文件
                    </el-button>
                </template>
                
                <el-button style="margin-left: 5px;" type="success" @click="handleSubmit">
                    上传文件至 「{{ form.title }}」
                </el-button>

                <template #tip>
                    <div style="margin-top: 5px; color: #43A152; padding-left: 5px;">
                        重复上传会覆盖原有文件，请做好备份
                    </div>
                </template>
        </el-upload>
    </div>
</template>

<script setup>
import { getCurrentInstance, onMounted, reactive, ref, watch } from 'vue';
import { genFileId } from 'element-plus';
import store from '@/store/index.js';
import ServerConfig from '@/config/server-config.js'
import UserService from '@/service/user/index.js';


const upload = ref();

const action = ref('');

const { proxy } = getCurrentInstance()

const data = reactive({
    token: '',
    paperId: null
})

const props = defineProps({
    form: {
        type: Object
    }
})

const form = reactive({
    id: null,
    title: null,
});

watch(
    () => props.form,
    (value, oldValue) => {
        Object.assign(form, value);
        Object.assign(data, {
            paperId: form.id
        })
    },
    { immediate: true, deep: true } // deep 设为 true 可以监听到 Object 内的属性变化
)

onMounted(() => {
    Object.assign(data, store.getters['UserStore/getUser']);
    action.value = `${ServerConfig.httpProtocol}://${ServerConfig.serverIp}/api/problem/paper/file/upload/files`;
})

const handleExceed = (files) => {
    upload.value.clearFiles()
    const file = files[0]
    Object.assign(file, {
        uid: genFileId()
    })
    upload.value.handleStart(file)
}

const handleSuccess = (response, uploadFile, uploadFiles) => {
    if (response.code == 200) {
        proxy.$message.success({
            message: '上传成功'
        })
    } else {
        proxy.$message.error({
            message: response.message
        })
    }
}

const handleSubmit = () => {
    upload.value.submit()
}

const handleBeforeUpload = (file) => {
    const isZip = file.name.endsWith('.zip') 
                || file.name.endsWith('.rar')
                || file.name.endsWith('.doc')
                || file.name.endsWith('.docx')
                || file.name.endsWith('.pdf');
    if (!isZip) {
        proxy.$message.error({
            message: '请选择正确类型的文件!'
        });
        upload.value.clearFiles();
        return false;
    }
}

</script>

<style>

</style>