<template>
    <el-dialog v-model="dialogVisible" title="创建文章" width="400">
        <el-form :model="form" label-width="70" style="padding: 10px 30px 10px 30px;">
            <el-form-item label="Title">
                <el-input style="width: 300px;" v-model="form.title" placeholder="请输入标题" autocomplete="off" />
            </el-form-item>
            <el-form-item label="Price">
                <el-input type="number" style="width: 300px;" v-model="form.price" placeholder="请输入价格" autocomplete="off" />
            </el-form-item>
        </el-form>

        <template #footer>
            <div>
                <el-button @click="close">取消</el-button>
                <el-button @click="confirm" :loading="confirmButtonLoding" type="primary">确定</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script setup>
import { reactive, ref, getCurrentInstance } from "vue";
import ShopService from "@/service/shop";

const confirmButtonLoding = ref(false);
let dialogVisible = ref(false);

const form = reactive({
    title: null,
    price: null
})

const { proxy } = getCurrentInstance()

const confirm = async () => {
    confirmButtonLoding.value = true;
    const res = await ShopService.postAdminCreateShopWorkAPI(form);
    confirmButtonLoding.value = false;
    if (res.code == 200) {
        location.reload();
        proxy.$message.success({
            message: '创建成功',
        })
    } else {
        proxy.$message.info({
            message: res.message,
        })
    }
    
}


const open = () => {
    dialogVisible.value = true;
};
 

const close = () => {
    dialogVisible.value = false;
}

defineExpose({
	open,
    close
});
</script>

<style scoped>



</style>