<template>
    <div class="toolBarBox toolBarBox-fixed">
        <div class="toolbox">
            <div class="toolbox-left">
                <img v-if="user.avatar == null" src="../../../../static/blog/tmpAvatar.png" 
                    style="width: 40px; height: 40px; object-fit: cover; border-radius: 50%; margin-left: 20px;">
                <img v-else :src="user.avatar"
                    style="width: 40px; height: 40px; object-fit: cover; border-radius: 50%; margin-left: 20px;">
            </div>
            <div class="toolbox-right">
                <div style="padding-right: 20px;">
                    <el-button :loading="buyButtonLoading" @click="handleBuyWork" v-show="!isBought"
                        style="border-radius: 20px; font-size: 20px;" type="warning">购买（支付平台跑路，联系QQ：767055299）
                    </el-button>
                    <el-button :loading="buyButtonLoading" @click="handleDownloadFile" v-show="isBought"
                        style="border-radius: 20px; font-size: 20px;" type="primary">下载
                    </el-button>
                </div>
            </div>
        </div>
        <el-dialog v-model="dialogVisible" width="400">
            <div style="display: flex; align-items: center; justify-content: center;">
                <img style="width: 300px;" :src="'https://varitall.oss-cn-beijing.aliyuncs.com/icon/qq-friend-qrcode.png'"/>
            </div>
        </el-dialog>
    </div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, onMounted } from 'vue';
import store from '@/store/index.js'
import UserService from '@/service/user/index.js';
import ShopService from '@/service/shop';

const { proxy } = getCurrentInstance();

const dialogVisible = ref(false);

const buyButtonLoading = ref(false);

const workId = ref(0);

const isBought = ref(false);

const user = reactive({
    email: '',
    avatar: '',
    username: ''
})

onMounted(async () => {
    Object.assign(user, store.getters['UserStore/getUser']);
    workId.value = proxy.$route.params.workId;
    const res = await ShopService.getCheckShopWorkUserAPI(workId.value, user.email);
    if (res.code == 200) {
        isBought.value = true;
    }
})


/**
 * 处理文件下载
 */
const handleDownloadFile = async () => {
    buyButtonLoading.value = true;
    const res = await ShopService.getShopWorkDownloadAPI(workId.value, user.email);
    buyButtonLoading.value = false;
}

/**
 * 处理购买商品按钮
 */
const handleBuyWork = () => {
    buyButtonLoading.value = true;
    dialogVisible.value = true;
    setTimeout(() => {
        buyButtonLoading.value = false;
    }, 1000)
}

</script>

<style scoped>
.toolBarBox {
    width: 50vw;
    height: 64px;

    border-radius: 5px;
    background-color: white;

    margin-top: 5px;

    box-shadow: 0 0 5px 1px #999;
}

.toolBarBox-fixed {
    width: 900px;

    position: fixed;
    z-index: 999;
    bottom: 0;
}

.toolbox {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;

}

.toolbox-left {
    width: 30%;
    height: 100%;

    align-items: center;

    display: flex;
    /* position: relative; */
}

.toolbox-right {
    /* width: 70%; */
    height: 100%;

    align-items: center;
    display: flex;

    margin-left: auto;
    /* position: relative; */

    caret-color: transparent;
}



@media screen and (max-width: 1080px) {
.toolBarBox-fixed {
    width: 95vw;

    position: fixed;
    z-index: 999;
    bottom: 0;
}
}

</style>