<template>
    <el-dialog v-model="dialogVisible" title="注册" width="400">
        <div style="display: flex; align-items: center; justify-content: center;">
            <!-- <vue3VideoPlay
                v-bind="options"
                :poster="options.cover"
            /> -->
            <img :src="'https://varitall.oss-cn-beijing.aliyuncs.com/icon/varitall-2.png'" 
                        style="height: 45px; margin-bottom: 20px;"/>
        </div>

        <el-form :model="form" label-width="70" style="padding: 10px 30px 10px 30px;">
            <el-form-item label="邮箱">
                <el-input v-model="form.email" placeholder="推荐使用 QQ 邮箱" autocomplete="off" />
            </el-form-item>
            <el-form-item label="密码">
                <el-input v-model="form.password" type="password" placeholder="请输入密码" autocomplete="off" />
            </el-form-item>
            <el-form-item label="验证码">
                <div style="display: flex; ">
                        <div>
                            <el-input v-model="form.code" type="code" placeholder="请输入验证码" autocomplete="off" />
                        </div>
                        <div style="display: flex; justify-content: center; align-items: center; padding-left: 20px;"> 
                            <!-- 原生button必须写上type='button' -->
                            <button type='button'  @click="handleSendCode" v-if="!isDisabled"
                                style="width: 80px; padding: 5px 10px 5px 10px; font-size: 16px; border: none; background-color: #33C0BA; color: #fff; border-radius: 5px;">
                                发送
                            </button>
                            <button type="button" class="button-disabled" v-if="isDisabled">
                                {{ content }}
                            </button>
                        </div>
                    </div>
            </el-form-item>
        </el-form>

        <template #footer>
            <div class="button-box" v-loading.fullscreen.lock="isLoading">
                <button @click="close" style="font-size: 16px;">取消</button>
                <button @click="register" style="margin-left: 10px; font-size: 16px;">确定</button>
                <p @click="toLogin" style="margin-left: 10px;">已有账号？去登录</p>
            </div>
        </template>
    </el-dialog>
</template>

<script setup>
import { reactive, ref, getCurrentInstance } from "vue";
import store from '@/store/index.js'
import UserService from '@/service/user/index.js';

let isLoading = ref(false);
let dialogVisible = ref(false);
const isDisabled = ref(false); 
const content = ref('Send'); 
const timer = ref();
const count = ref();

const options = reactive({
  width: "350px", //播放器宽度
  height: "300px", //播放器高度
  color: "#409eff", //主题色
  title: "", //视频名称
  cover: "https://varitall.oss-cn-beijing.aliyuncs.com/markdown/R-C.jpg",
  src: "https://cdn.jsdelivr.net/gh/xdlumia/files/video-play/IronMan.mp4", //视频源
  muted: false, //静音
  webFullScreen: false,
  speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"], //播放倍速
  autoPlay: false, //自动播放
  loop: false, //循环播放
  mirror: false, //镜像画面
  ligthOff: false, //关灯模式
  volume: 0.3, //默认音量大小
  control: true, //是否显示控制
  controlBtns: [
    "audioTrack",
    "quality",
    "speedRate",
    "volume",
    "setting",
    "pip",
    "pageFullScreen",
    "fullScreen",
  ], //显示所有按钮,
});

const form = reactive({
    email: null,
    password: null,
    code: null
})

const { proxy } = getCurrentInstance()

const register = async () => {
    isLoading.value = true;
    const res = await UserService.postUserRegisterAPI(form);
    if (res.code === 200) {
        toLogin();
        proxy.$message.success({
            message: '注册成功'
        })
	} else {
        proxy.$message.error({
            message: res.message
        })
	}
    isLoading.value = false
}

const emit = defineEmits(['toLogin'])

const toLogin = () => {
    close();
    emit('toLogin');
}

const open = () => {
    dialogVisible.value = true;
};
 

const close = () => {
    dialogVisible.value = false;
}

defineExpose({
	open,
    close
});


const handleSendCode = async () => {
	if (form.email) {
		isDisabled.value = true; // 进入倒计时
		
		const res = await UserService.getEmailCodeAPI(form.email);
		if (res.code == 200) {
            proxy.$message.success({
                message: '验证码发送成功'
            })
		} else {
			proxy.$message.error({
                message: res.message
            })
		}
		
		const TIME_COUNT = 60;
		count.value = TIME_COUNT;
		timer.value = setInterval(() => {
			if (count.value > 0 && count.value <= TIME_COUNT){
				// 倒计时时不可点击
				isDisabled.value = true
				// 计时秒数
				count.value --
				// 更新按钮的文字内容
				content.value = count.value + 's'
			} else {
				// 倒计时完，可点击
				isDisabled.value = false
				// 更新按钮文字内容
				content.value = 'Send'
				// 清空定时器!!!
				clearInterval(timer.value)
				timer.value = null
			}
		}, 1000)
	} else {
        proxy.$message.warning({
            message: '请输入邮箱'
        })
    }
};
</script>

<style scoped>
.button-disabled {
    background-color: #EEEEEE !important; 
    color: #CCCCCC !important;
	
	font-size: 16px; 
	border: none; 
	border-radius: 5px;

    padding: 5px 10px 5px 10px; 
}
</style>