import request from "@/util/request"
/**
 * 用户注册接口
 */
export default async (data) => {
    return request({
        method: 'POST',
        url: '/api/core/user/register',
        data: data
    })
}