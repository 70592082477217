import request from "@/util/request.js"


/**
 * 管理员获取题目标签分页
 * @param {*} data 
 * @returns 
 */
const getProblemTagPageAdminAPI = async(data) => {
    return request({
        method: 'GET',
        url: `/admin/problem/tag/page`,
        params: data
    })
}

/**
 * 管理员保存标签 
 * @param {*} data 
 * @returns 
 */
const saveProblemTagAdminAPI = async(data) => {
    return request({
        method: 'POST',
        url: '/admin/problem/tag/save',
        data: data
    })
}

/**
 * 管理员保存菜单
 * @param {*} data 
 * @returns 
 */
const saveProblemMenuAdminAPI = async(data) => {
    return request({
        method: 'POST',
        url: `/admin/problem/menu/save`,
        data: data
    })
}


/**
 * 管理员获取菜单分页
 * @param {*} data 
 */
const getProblemMenuPageAdminAPI = async(data) => {
    return request({
        method: 'GET',
        url: '/admin/problem/menu/page',
        params: data
    })
}


/**
 * 管理员获取学科分页
 * @param {*} data 
 */
const getProblemSubjectPageAdminAPI = async(data) => {
    return request({
        method: 'GET',
        url: '/admin/problem/subject/page',
        params: data
    })
}


/**
 * 管理员保存学科
 * @param {*} data 
 * @returns 
 */
const saveProblemSubjectAdminAPI = async(data) => {
    return request({
        method: 'POST',
        url: '/admin/problem/subject/save',
        data: data
    })
}

/**
 * 获取 menu ID 列表
 * @returns 
 */
const getProblemMenuIdListAPI = async(subjectId) => {
    return request({
        method: 'GET',
        url: `/api/problem/menu/list/id`,
        params: {
            subjectId: subjectId
        }
    })
}

/**
 * 获取subject ID列表
 * @returns 
 */
const getProblemSubjectIdListAPI = async() => {
    return request({
        method: 'GET',
        url: '/api/problem/subject/id/list'
    })
}

/**
 * 获取tag ID列表
 * @returns 
 */
const getProblemTagIdListAPI = async() => {
    return request({
        method: 'GET',
        url: '/api/problem/tag/id/list'
    })
}

/**
 * 获取 menu list
 * @param {*} subjectId 
 * @returns 
 */
const getProblemMenuListAPI = async(subjectId) => {
    return request({
        method: 'GET',
        url: `/api/problem/menu/list/${subjectId}`
    });
}

/**
 * 保存题库菜单
 * @param {*} data 
 */
const saveProblemMenuAPI = async (data) => {
    return request({
        method: 'POST',
        url: '/api/problem/menu/save',
        data: data
    })
}

/**
 * 删除题库菜单
 * @param {*} data 
 * @returns 
 */
const deleteProblemMenuAPI = async(data) => {
    return request({
        method: 'POST',
        url: '/api/problem/menu/delete',
        data: data
    })
}

/**
 * 根据 tagIdList 获取 paperList
 * @param {*} data 
 * @returns 
 */
const getProblemPaperListByTagIdsAPI = async(data) => {
    return request({
        method: 'POST',
        url: '/api/problem/paper/list/by/tag/ids',
        data: data
    })
}

/**
 * 保存试题
 * @param {*} data 
 * @returns 
 */
const saveProblemPaperAPI = async(data) => {
    return request({
        method: 'POST',
        url: '/api/problem/paper/save',
        data: data
    })
}


/**
 * 获取某个用户的试题列表
 * @returns 
 */
const getProblemPaperListByToken = async() => {
    return request({
        method: 'GET',
        url: '/api/problem/paper/list/token'
    })
}


/**
 * 获取tag的 { id，value } 
 * @returns 
 */
const getProblemTagIdMapAPI = async() => {
    return request({
        method: 'GET',
        url: '/api/problem/tag/id/map'
    })
}

/**
 * 根据ID删除试题
 * @param {*} paperId 
 * @returns 
 */
const deleteProblemPaperByIdAPI = async(paperId) => {
    return request({
        method: 'GET',
        url: `/api/problem/paper/delete/${paperId}`
    })
}

export default {
    getProblemMenuListAPI,
    getProblemMenuIdListAPI,
    getProblemSubjectIdListAPI,
    getProblemTagIdListAPI,
    saveProblemMenuAPI,
    deleteProblemMenuAPI,
    getProblemPaperListByTagIdsAPI,
    saveProblemPaperAPI,
    getProblemPaperListByToken,
    getProblemTagIdMapAPI,
    deleteProblemPaperByIdAPI,

    getProblemTagPageAdminAPI,
    getProblemMenuPageAdminAPI,
    getProblemSubjectPageAdminAPI,
    saveProblemTagAdminAPI,
    saveProblemMenuAdminAPI,
    saveProblemSubjectAdminAPI
}

