const nullIndex = {
    title: null,
    subjectId: null,
    menuActiveIndex: null,
    subMenuActiveIndex: null
}

const nullTag = {
    id: null,
    title: null,
    description: null
}

export default {
	namespaced: true,
	state: {
        index: JSON.parse(localStorage.getItem('problemMenuIndex') || JSON.stringify(nullIndex)),
        tag: JSON.parse(localStorage.getItem('tag') || JSON.stringify(nullTag)),
	},
	mutations: {
		updateIndex(state, data) {
			state.index = data;
			this.commit('ProblemStore/saveIndexToStorage');
		},
		saveIndexToStorage(state) {
			localStorage.setItem('problemMenuIndex', JSON.stringify(state.index));
		},
		removeIndex(state) {
			localStorage.removeItem('problemMenuIndex');
			this.commit('ProblemStore/updateIndex', nullIndex)
		},

		updateTag(state, data) {
			state.tag = data;
			this.commit('ProblemStore/saveTagToStorage');
		},
        saveTagToStorage(state) {
			localStorage.setItem('tag', JSON.stringify(state.tag));
		},
        removeTag(state) {
			localStorage.removeItem('tag');
			this.commit('ProblemStore/updateTag', nullTag)
		},
	},
	actions: {
		
	},
	getters: {
		getIndex(state) {
			return state.index;
		},
        
        getTag(state) {
            return state.tag;
        }
	},
};