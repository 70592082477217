<template>
    <div class="my-calendar-box">
        <div>
            <el-button @click="handleOpenDrawer" style="width: 40px; height: 40px; border: none;">
                <img style="width: 40px; height: 40px;" src="../../../static/calendar/calendar.png">
            </el-button>
        </div>
    </div>
    <el-drawer v-model="drawerVisible" title="日历"
                :direction="elDrawerAttr.direction" :size="elDrawerAttr.size" >
        <div>
            <el-calendar v-model="value"></el-calendar>
        </div>
    </el-drawer>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";

const value = ref(new Date());

const drawerVisible = ref(false); // 抽屉是否可见

const windowWidth = ref(0); // 屏幕宽度

const elDrawerAttr = reactive({ // 抽屉参数
    direction: 'rtl',
    size: '30%'
})


onMounted(() => {
    getWindowResize();
    window.addEventListener('resize', getWindowResize);
})


// 获取屏幕尺寸
const getWindowResize = () => {
    windowWidth.value = window.innerWidth;
    if (windowWidth.value > 800) {
        Object.assign(elDrawerAttr, {
            direction: 'rtl',
            size: '50%'
        })
    } else {
        Object.assign(elDrawerAttr, {
            direction: 'btt',
            size: '80%'
        })
    }
}

/**
 * 打开抽屉
 */
 const handleOpenDrawer = () => {
    drawerVisible.value = true;
}
</script>

<style scoped>
.my-calendar-box {
    position: fixed;
    bottom: 5vh;
    left: 2vw;
    z-index: 999;
}

@media screen and (max-width: 1080px) {
.my-calendar-box {
    bottom: 4vh;
    left: 5vw;
}
}
</style>