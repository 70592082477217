import request from "@/util/request";
import store from '@/store/index.js'

/**
 * 检查token是否有效
 * @returns 
 */
export default async () => {
	const { email } = store.getters['UserStore/getUser']
	return request({
		method: 'GET',
		url: '/api/core/user/checkToken/' + email
	})
}