import request from "@/util/request"

const getShopWorkListAPI = (data) => {
    return request({
        method: 'GET',
        url: '/api/shop/work/list',
        params: data
    })
}

/**
 * 根据商品id获取信息
 * @param {*} workId 
 * @returns 
 */
const getShopWorkDetailAPI = (workId) => {
    return request({
        method: 'GET',
        url: `/api/shop/work/detail/${workId}`
    })
}

/**
 * 更新商品信息
 * @param {*} data 
 * @returns 
 */
const postAdminUpdateShopWorkAPI = (data)=> {
    return request({
        method: 'POST',
        url: '/admin/shop/work/update',
        data: data
    })
}

/**
 * 删除商品
 * @param {*} workId 
 * @returns 
 */
const postAdminDeleteShopWorkAPI = (workId) => {
    return request({
        method: 'POST',
        url: `/admin/shop/work/delete/${workId}`,
    })
}

/**
 * 创建商品
 * @param {*} data 
 */
const postAdminCreateShopWorkAPI = (data) => {
    return request({
        method: 'POST',
        url: '/admin/shop/work/create',
        data: data
    })
}

/**
 * 检查该用户是否购买了该商品
 * @param {*} workId 
 * @param {*} email 
 * @returns 
 */
const getCheckShopWorkUserAPI = (workId, email) => {
    return request({
        method: 'GET',
        url: `/api/shop/work/user/check/${workId}/${email}`
    })
}


/**
 * 下载商品
 * @param {*} workId 
 * @param {*} email 
 */
const getShopWorkDownloadAPI = (workId, email) => {
    // window.open(`/api/shop/work/file/download/${workId}/${email}`);
    return request({
        method: 'GET',
        url: `/api/shop/work/file/download/${workId}/${email}`
    })
}

export default {
    getShopWorkListAPI,
    getShopWorkDetailAPI,
    getCheckShopWorkUserAPI,
    getShopWorkDownloadAPI,

    postAdminDeleteShopWorkAPI,
    postAdminUpdateShopWorkAPI,
    postAdminCreateShopWorkAPI
}

