<template>
    <div>
        <el-upload
            ref="upload"
            style="width: 100%;"
            :action="action"
            :limit="1"
            :data="user"
            :multiple="false"
            :method="'post'"
            :name="'multipartFile'"
            :accept="'.zip, .rar'"
            :auto-upload="false"
            :show-file-list="true"
            :on-exceed="handleExceed"
            :on-success="handleSuccess"
            :before-upload="handleBeforeUpload">
                <template #trigger>
                    <el-button type="primary">
                        选择文件
                    </el-button>
                </template>
                
                <el-button style="margin-left: 5px;" type="success" @click="handleSubmit">
                    上传文件
                </el-button>
        </el-upload>
    </div>
</template>

<script setup>
import { getCurrentInstance, onMounted, reactive, ref } from 'vue';
import { genFileId } from 'element-plus';
import store from '@/store/index.js';
import ServerConfig from '@/config/server-config.js'


const upload = ref();

const action = ref('');

const workId = ref('');

const { proxy } = getCurrentInstance()

const user = reactive({
    email: '',
    token: ''
})

onMounted(() => {
    workId.value = proxy.$route.params.workId;
    Object.assign(user, {
        email: store.getters['UserStore/getUser'].email,
        token: store.getters['UserStore/getUser'].token
    })
    action.value = `${ServerConfig.httpProtocol}://${ServerConfig.serverIp}/api/shop/work/file/upload/${workId.value}`;
})

const handleExceed = (files) => {
    upload.value.clearFiles()
    const file = files[0]
    Object.assign(file, {
        uid: genFileId()
    })
    upload.value.handleStart(file)
}

const handleSuccess = (response, uploadFile, uploadFiles) => {
    if (response.code == 200) {
        proxy.$message.success({
            message: '上传成功'
        })
    } else {
        proxy.$message.error({
            message: '上传失败，请检查文件大小'
        })
    }
}

const handleSubmit = () => {
    upload.value.submit()
}

const handleBeforeUpload = (file) => {
    const isZip = file.name.endsWith('.zip') || file.name.endsWith('.rar');
    if (!isZip) {
        proxy.$message.error({
            message: '请选择 zip/rar 文件!'
        });
        upload.value.clearFiles();
        return false;
    }
}

</script>

<style>

</style>