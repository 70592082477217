<template>
    <div>
        <el-upload
            ref="upload"
            style="width: 100%;"
            :action="action"
            :limit="1"
            :data="user"
            :multiple="false"
            :method="'post'"
            :name="'multipartFile'"
            :accept="'image/*'"
            :auto-upload="false"
            :show-file-list="true"
            :on-exceed="handleExceed"
            :on-success="handleSuccess">
                <template #trigger>
                    <el-button type="primary">
                        选择文件
                    </el-button>
                </template>
                
                <el-button style="margin-left: 5px;" type="success" @click="handleSubmit">
                    上传头像
                </el-button>

                <el-button style="margin-left: 5px;" type="warning" @click="useQQAvatar">
                    使用 QQ 头像
                </el-button>

                <template #tip>
                    <div style="margin-top: 5px; color: #43A152; padding-left: 5px;">
                        jpg/png 文件大小需要小于 1MB
                    </div>
                </template>
        </el-upload>
    </div>
</template>

<script setup>
import { getCurrentInstance, onMounted, reactive, ref } from 'vue';
import { genFileId } from 'element-plus';
import store from '@/store/index.js';
import ServerConfig from '@/config/server-config.js'
import UserService from '@/service/user/index.js';


const upload = ref();

const action = ref('');

const { proxy } = getCurrentInstance()

const user = reactive({
    email: '',
    token: ''
})

onMounted(() => {
    Object.assign(user, store.getters['UserStore/getUser'])
    action.value = `${ServerConfig.httpProtocol}://${ServerConfig.serverIp}/api/core/user/file/upload/avatar`;
})

const useQQAvatar = async() => {
    const res = await UserService.setUserAvatarByQQAPI();
    if (res.code == 200) {
        Object.assign(user, {
            avatar: res.data
        });
        store.commit('UserStore/updateUser', user);
        location.reload();
        proxy.$message.success({
            message: '更新成功'
        })
    } else {
        proxy.$message.error({
            message: res.message
        })
    }
}

const handleExceed = (files) => {
    upload.value.clearFiles()
    const file = files[0]
    Object.assign(file, {
        uid: genFileId()
    })
    upload.value.handleStart(file)
}

const handleSuccess = (response, uploadFile, uploadFiles) => {
    if (response.code == 200) {
        // 更新 store 中的 avatar 属性
        const res = store.getters['UserStore/getUser'];
        Object.assign(res, {
            avatar: response.data
        })
        store.commit('UserStore/updateUser', res)
        location.reload();

        proxy.$message.success({
            message: '上传成功'
        })
    } else {
        proxy.$message.error({
            message: '上传失败，请检查文件大小'
        })
    }
}

const handleSubmit = () => {
    upload.value.submit()
}

</script>

<style>

</style>