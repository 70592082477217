<template>
    <el-dialog v-model="dialogVisible" title="创建/编辑用户" width="400">
        <el-form :model="form" label-width="70" style="padding: 10px 30px 10px 30px;">
            <el-form-item label="用户名">
                <el-input style="width: 300px;" v-model="form.username" placeholder="请输入用户名" autocomplete="off" />
            </el-form-item>
            <el-form-item label="密码">
                <el-input style="width: 300px;" v-model="form.password" placeholder="请输入密码" autocomplete="off" />
            </el-form-item>
            <el-form-item label="邮箱">
                <el-input style="width: 300px;" v-model="form.email" placeholder="请输入邮箱" autocomplete="off" />
            </el-form-item>
            <el-form-item label="头像">
                <el-input style="width: 300px;" v-model="form.avatar" placeholder="请输入头像外链url" autocomplete="off" />
            </el-form-item>
            <el-form-item label="权限">
                <el-input style="width: 300px;" type="number" v-model="form.privilege" placeholder="请输入权限" autocomplete="off" />
            </el-form-item>
            <el-form-item label="手机号">
                <el-input style="width: 300px;" v-model="form.phone" placeholder="请输入手机号" autocomplete="off" />
            </el-form-item>
        </el-form>

        <template #footer>
            <div>
                <el-button @click="close">取消</el-button>
                <el-button @click="confirm" :loading="confirmButtonLoding" type="primary">确定</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script setup>
import { reactive, ref, getCurrentInstance, watch } from "vue";
import UserService from "@/service/user/index.js";
import CryptoJS from "crypto-js";

const { proxy } = getCurrentInstance()

const confirmButtonLoding = ref(false);

let dialogVisible = ref(false);

const props = defineProps({
    form: {
        type: Object
    }
})

const form = reactive({
    id: '',
    username: '',
    password: '',
    email: '',
    avatar: '',
    privilege: '',
    phone: ''
})

watch(
    () => props.form,
    (value, oldValue) => {
        Object.assign(form, value);
    },
    { immediate: true, deep: true } // deep 设为 true 可以监听到 Object 内的属性变化
)

const confirm = async () => {
    confirmButtonLoding.value = true;
    const res = await UserService.postSaveUserAdminAPI(form);
    confirmButtonLoding.value = false;
    if (res.code == 200) {
        location.reload();
        proxy.$message.success({
            message: '保存成功',
        })
    } else {
        proxy.$message.info({
            message: res.message,
        })
    }
    
}


const open = () => {
    dialogVisible.value = true;
};
 

const close = () => {
    dialogVisible.value = false;
}

defineExpose({
	open,
    close
});
</script>

<style>

</style>