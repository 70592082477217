<template>
    <div style="z-index: 999; background-color: #fff; position: fixed; height: 6vh; width: 100vw; box-shadow: 0px 1px 0px rgba(0,0,0,.2);">
        <div style="height: 100%; display: flex; width: 60vw; margin: 0 auto;">
            <div class="nav-item-box">
                <div class="router-link-item">
                    <img :src="'https://varitall.oss-cn-beijing.aliyuncs.com/icon/varitall-1.png'" 
                        style="height: 45px; margin-right: 80px;"/>
                </div>
            </div>
            
            <div v-for="item in menuList" :key="item.index" class="nav-item-box" 
                :class="selectedIndex == item.index ? 'header-active':''" @click="getTopic(item.index)">
                <router-link :to="item.path" class="router-link-item">
                    <img :src="item.url" style="width: 22px; margin-right: 5px;"/>
                    <p class="p-title-kaiti"> {{ item.title }} </p>
                </router-link>
            </div>
            <div class="nav-item-box" style="margin-left: auto;" :class="selectedIndex == menuList.length + 1 ? 'header-active':''" @click="getTopic(menuList.length + 1)">
                <div v-show="user.email == null" class="button-box">
                    <button style="color: #fff; width: 100px;" @click="handleLogin">
                        登录
                    </button>
                </div>
                <div v-show="user.email != null" style="height: 100%;">
                    <el-dropdown size="large" style="height: 100%;"> 
                        <div class="user-item">
                            <div class="router-link-item"  @click="handlePerson">
                                <img v-if="user.avatar" :src="user.avatar" style="width: 30px; height: 30px; object-fit:cover;  border-radius: 50%;"/>
                                <img v-else src="../../../assets/logo.png" style="width: 30px; height: 30px; object-fit:cover; border-radius: 50%;"/>
                                <div style="margin-left: 5px;">
                                    <p class="p-title-kaiti" style="font-size: 18px; width: 70px; color: #000;">
                                        {{ user.username }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <!-- <el-dropdown-item>
                                    <div>
                                        <p @click="handleMyShopWork" style="caret-color: transparent;">我的商品</p>
                                    </div>
                                </el-dropdown-item> -->
                                <el-dropdown-item>
                                    <div>
                                        <p @click="logout" style="caret-color: transparent;">退出</p>
                                    </div>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </div>
        </div>
    </div>
    <my-login ref="mylogin" @toRegister="handleRegister"></my-login>
    <my-register ref="myregister" @toLogin="handleLogin"></my-register>
</template>

<script setup>
import { ref, reactive, onMounted, getCurrentInstance } from 'vue'
import store from '@/store/index.js'
import UserService from '@/service/user/index.js';

const { proxy } = getCurrentInstance()
const mylogin = ref();
const myregister = ref();
const selectedIndex = ref(1);

const user = reactive({
    email: null,
    username: null,
    avatar: null,
    token: null,
    privilieg: 0
})

const menuList = reactive([
    {
        index: 1,
        path: '/index',
        title: '首页',
        url: 'https://varitall.oss-cn-beijing.aliyuncs.com/icon/index.png'
    },
    {
        index: 2,
        path: '/blog',
        title: '博客',
        url: 'https://varitall.oss-cn-beijing.aliyuncs.com/icon/blog.png'
    },
    {
        index: 3,
        path: '/shop',
        title: '面包',
        url: 'https://varitall.oss-cn-beijing.aliyuncs.com/icon/shop.png'
    },
    {
        index: 4,
        path: '/problem',
        title: '题库',
        url: 'https://varitall.oss-cn-beijing.aliyuncs.com/icon/problem.png'
    }
])


onMounted(async () => {
    const check_token = await UserService.checkTokenAPI();
	if (check_token.code == 200) {
		Object.assign(user, store.getters['UserStore/getUser']);
	} else {
        store.commit('UserStore/removeUser');
        Object.assign(user, {
            email: null,
            username: null,
            privilege: 0,
            avatar: null,
            token: null
        });
		proxy.$message.warning({
            message: '身份信息已过期，请登录/注册'
        })
	}

    // 判断当前是哪个模块，设置menu下标
    const path = proxy.$router.currentRoute.value.path;
    for (let item of menuList) {
        if (path.includes(item.path)) {
            selectedIndex.value = item.index;
        }
    }
    
})

/**
 * 处理登录
 */
const handleLogin = () => {
    mylogin.value.open();
}

/**
 * 处理注册
 */
const handleRegister = () => {
    myregister.value.open();
}

/**
 * 注销
 */
const logout = () => {
    store.commit('UserStore/removeUser');
    location.reload();
}

/**
 * 转到个人中心页面
 */
const handlePerson = () => {
    proxy.$router.push('/user/person');
}

/**
 * 转到个人购买的商品页面
 */
const handleMyShopWork = () => {

}

/**
 * 修改选中的样式
 * @param {*} index 
 */
const getTopic = (index) => {
    selectedIndex.value = index;
}
</script>

<style scoped>
.nav-item-box{
    height: 100%;
    width: 10%;
    padding: 0 10px 0 10px;
}
.nav-item-box p {
    font-size: 20px;
} 
.nav-item-box button {
    font-size: 16px;
}


.router-link-item {
    height: 100%; 

    display: flex; 
    
    align-items: center; 
    justify-content: center;
}


.user-item {
    height: 100%; 
    caret-color: transparent;
}
.user-item > div:hover {
    color:#06b1f5;
    text-decoration: none;

    cursor: pointer;
    opacity: .8;
}


/* 隐藏黑色外边框 */
:deep(.el-tooltip__trigger:focus-visible) {
    outline: unset;
}

@media screen and (max-width: 1500px) {
.nav-item-box {
    width: auto;
}  
.nav-item-box p {
    
}  
.nav-item-box button {
    
}
}


a {
    caret-color: transparent;
}
a:link,
a:visited {
    color:#000;
    text-decoration: none;
}
a:hover,
a:active {
    color:#06b1f5;
    text-decoration: none;
    /* border-bottom: 1px solid #06b1f5; */
}


.header-active {
    border-bottom: 2px solid #06b1f5;
}
.header-active p {
    text-decoration: none;
    color:#06b1f5;
}


</style>