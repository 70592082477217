import request from "@/util/request";

/**
 * 管理员删除用户
 */
export default async(userId) => {
    return request({
        method: 'POST',
        url: `/admin/core/user/delete/${userId}`
    });
}
