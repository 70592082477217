import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

const routes = [
    {
        // 前台
        path: '/',
        name: 'index',
        redirect: '/index',
        component: () => import('@/views/index.vue'),
        children: [
            {
                // 博客
                path: 'blog',
                name: '博客',
                component: () => import('@/views/blog/blog-index.vue'),
                redirect: '/blog/article/list',
                children: [
                    {
                        path: 'article/list',
                        name: '博客列表',
                        component: () => import('@/views/blog/blog-article-list.vue'),
                    },
                    {
                        path: 'article/detail/:articleId',
                        name: '文章内容',
                        component: () => import('@/views/blog/blog-article-detail.vue')
                    },
                    {
                        path: 'article/edit/:articleId',
                        name: '编辑博客文章',
                        component: () => import('@/views/blog/blog-article-edit.vue')
                    },
                ]
            },
            {
                // 商店
                path: 'shop',
                name: '商店',
                component: () => import('@/views/shop/shop-index.vue'),
                redirect: '/shop/work/list',
                children: [
                    {
                        path: 'work/list',
                        name: '商品列表',
                        component: () => import('@/views/shop/shop-work-list.vue'),
                    },
                    {
                        path: 'work/detail/:workId',
                        name: '商品内容',
                        component: () => import('@/views/shop/shop-work-detail.vue')
                    },
                    {
                        path: 'work/edit/:workId',
                        name: '编辑商品',
                        component: () => import('@/views/shop/shop-work-edit.vue')
                    },
                ]
            },
            {
                // 题库
                path: 'problem',
                name: '题库',
                component: () => import('@/views/problem/problem-index.vue'),
                redirect: '/problem/home',
                children: [
                    {
                        path: 'home',
                        name: '题库中心',
                        component: () => import('@/views/problem/problem-home.vue'),
                    },
                    {
                        path: 'menu/edit',
                        name: '题目菜单编辑',
                        component: () => import('@/views/problem/problem-menu-edit.vue')
                    },
                    {
                        path: 'paper/detail/:paperId',
                        name: '试题详情页',
                        component: () => import('@/views/problem/problem-paper-detail.vue')
                    },
                    {
                        path: 'tag/edit',
                        name: '标签编辑',
                        component: () => import('@/views/problem/problem-tag-edit.vue')
                    }
                ]
            },
            {
                path: 'user',
                name: '用户',
                component: () => import('@/views/user/user-index.vue'),
                redirect: '/user/person',
                children: [
                    {
                        path: 'person',
                        name: '用户信息',
                        component: () => import('@/views/user/user-person.vue'),
                    },
                    {
                        path: 'paper',
                        name: '用户试题',
                        component: () => import('@/views/user/user-paper.vue')
                    }
                ]
            },
            {
                path: 'index',
                name: '首页',
                component: () => import('@/views/user/index.vue')
            },
            
            
        ]
    },
    {
        // 管理员后台
        path: '/admin',
        name: 'admin-index',
        component: () => import('@/views/admin/backend/index.vue'),
        redirect: '/admin/home',
        children: [
            {
                path: 'home',
                name: 'admin-home',
                component: () => import('@/views/admin/backend/home.vue')
            },
            {
                path: 'user/list',
                name: 'admin-user-list',
                component: () => import('@/views/admin/backend/user/user-list.vue')
            },
            {
                path: 'user/edit',
                name: 'admin-user-edit',
                component: () => import('@/views/admin/backend/user/user-edit.vue')
            },
            {
                path: 'article/comment/list',
                name: 'admin-article-comment-list',
                component: () => import('@/views/admin/backend/article/article-comment-list.vue')
            },
            {
                path: 'problem/tag/list',
                name: 'problem-tag-list',
                component: () => import('@/views/admin/backend/problem/problem-tag-list.vue')
            },
            {
                path: 'problem/menu/list',
                name: 'problem-menu-list',
                component: () => import('@/views/admin/backend/problem/problem-menu-list.vue')
            },
            {
                path: 'problem/subject/list',
                name: 'problem-subject-list',
                component: () => import('@/views/admin/backend/problem/problem-subject-list.vue')
            }
        ]
    },
    {
        // 管理员后台登录
        path: '/admin/login',
        name: 'admin-login',
        component: () => import('@/views/admin/user/login.vue')
    },
    {
        // 管理员后台注册
        path: '/admin/register',
        name: 'register',
        component: () => import('@/views/admin/user/register.vue')
    },
    {
        // 管理员后台修改密码
        path: '/admin/modify-password',
        name: 'modify-password',
        component: () => import('@/views/admin/user/modify-password.vue')
    }
  
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
