import request from "@/util/request.js"


/**
 * 用户修改密码
 */
export default async (data) => {
	return request({
	    method: 'POST',
	    url: '/api/core/user/modifyPassword',
	    data: data
	})
}
