import request from "@/util/request.js"

/**
 * 修改用户名
 */
export default async (data) => {
	return request({
		method: 'POST',
		url: '/api/core/user/modifyUsername',
		data: data
	})
}