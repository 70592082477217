import request from "@/util/request";

/**
 * 管理员添加/修改用户
 */
export default async (data) => {
    return request({
        method: 'POST',
        url: '/admin/core/user/save',
        data: data
    })
}
