<template>
    <el-dialog v-model="dialogVisible" title="创建/编辑" width="600">
        <template #header>
            <span style="font-size: 18px;">创建/编辑
                <span style="color: red;">（点击确定后才会保存修改）</span>
            </span>
        </template>
        <el-form :model="form" label-width="70" :size="'large'" label-position="left" style="padding: 10px 30px 10px 50px;">
            <el-form-item label="父菜单">
                <el-select v-model="form.parentId" v-if="menuList.length > 0" style="width: 300px;">
                    <el-option v-for="item in menuList" :key="item.id" :value="item.id">
                        {{ item.title }}
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="标签">
                <my-problem-tag-cascader 
                    :cascader="cascader"
                    :form="form"
                    @handleChange="handleTagChange">
                </my-problem-tag-cascader>
            </el-form-item>

            <el-form-item label="标题">
                <el-input style="width: 300px;" v-model="form.title" placeholder="输入标题" autocomplete="off" />
            </el-form-item>

            <el-form-item label="描述">
                <el-input type="textarea" style="width: 300px;" v-model="form.description" placeholder="输入描述" autocomplete="off" />
            </el-form-item>
        </el-form>

        <template #footer>
            <div>
                <el-button @click="close">取消</el-button>
                <el-button @click="confirm" :loading="confirmButtonLoding" type="primary">确定</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script setup>
import { reactive, ref, getCurrentInstance, watch } from "vue";
import ProblemService from '@/service/problem/index.js';
import store from '@/store/index.js';


const { proxy } = getCurrentInstance()

const confirmButtonLoding = ref(false);

let dialogVisible = ref(false);

const props = defineProps({
    form: {
        type: Object
    }
})

const cascader = reactive({
    props: {
        multiple: false
    }
})

const index = reactive({
    title: '',
    subjectId: null
})

const form = reactive({});

const menuList = reactive([]);

watch(
    () => props.form,
    (value, oldValue) => {
        Object.assign(form, value);
        Object.assign(form, {
            tagIdList: [value.tagId]
        })
    },
    { immediate: true, deep: true } // deep 设为 true 可以监听到 Object 内的属性变化
)

const confirm = async () => {
    confirmButtonLoding.value = true;
    const res = await ProblemService.saveProblemMenuAPI(form);
    confirmButtonLoding.value = false;
    if (res.code == 200) {
        proxy.$message.success({
            message: '保存成功',
        })
        location.reload();
    } else {
        proxy.$message.info({
            message: res.message,
        })
    }
    
}

/**
 * 根据 subjectId 获取菜单列表
 */
const getMenuIdList = async() => {
    Object.assign(index, store.getters['ProblemStore/getIndex']);
    if (index.subjectId == null) {
        return;
    }
    const res = await ProblemService.getProblemMenuIdListAPI(index.subjectId);
    if (res.code == 200) {
        menuList.length = 0;
        if (res.data.length != 0) {
            for (let item of res.data) {
                menuList.push(item);
            }
        }
    } else {
        proxy.$message.error({
            message: res.message
        })
    }
}

/**
 * 处理选择标签变化
 * @param value 
 */
const handleTagChange = async(value) => {
    Object.assign(form, {
        tagId: value
    })
}


const open = async () => {
    dialogVisible.value = true;
    await getMenuIdList();
};
 

const close = () => {
    dialogVisible.value = false;
}

defineExpose({
	open,
    close
});
</script>

<style>

</style>