import request from "@/util/request"

/**
 * 获取用户列表
 */
export default async (data) => {
    return request({
        method: 'GET',
        url: '/admin/core/user/page',
        params: data
    })
}
